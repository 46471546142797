import type { ReactElement } from 'react';

import InfoBox from './InfoBox';
import DentistsNotFound from './NotFound';
import DentistsFound from './DentistsFound';

import { useDentistsContext } from '..';

export default function MapStage(): ReactElement {
  const { docList } = useDentistsContext();

  const total = docList.length;
  return (
    <>
      <InfoBox total={total} />
      <div className="mt-5 min-[1000px]:p-2 flex justify-between items-end max-[748px]:flex-col max-[748px]:items-center gap-5">
        {total === 0 ? <DentistsNotFound /> : <DentistsFound />}
      </div>
    </>
  );
}
