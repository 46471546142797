import { type ReactElement, useState, useEffect } from 'react';

import { useTLContext, type Step } from '.';
import { useWindowSizeStore } from '../../../stores/useWindowSizeStore';
import styles from './timeline.module.css';

type Select = { id: number; idx: number };
interface ListProp {
  steps: Step[];
  current: Select;
  setCurrent: React.Dispatch<React.SetStateAction<Select>>;
}

const MainList = ({ steps, setCurrent, current }: ListProp) => (
  <>
    {steps.map((s, i) => (
      <div
        key={s.title}
        className={styles.timelineItem}
        style={{ width: `${(100 / steps.length - 2).toFixed(2)}%` }}
      >
        <div className="-mt-6 flex flex-col justify-center items-center">
          {/* <p className="text-high-emphasis text-lg">
            {i % 2 === 0 ? '\u00A0' : s.text}
          </p> */}
          <h6 className="text-new-darkblue font-bold">
            {i % 2 === 0 ? '\u00A0' : s.title}
          </h6>
        </div>

        <button
          disabled={current.idx === i}
          onClick={() => setCurrent({ idx: i, id: s.id })}
          className={styles.selector}
        />

        <div className="flex flex-col justify-center items-center">
          <h6 className="text-new-darkblue font-bold">
            {i % 2 === 0 ? s.title : '\u00A0'}
          </h6>
          {/* <p className="text-high-emphasis text-lg">
            {i % 2 === 0 ? s.text : '\u00A0'}
          </p> */}
        </div>
      </div>
    ))}
  </>
);

const BigWrapper = ({ current, setCurrent, steps }: ListProp) => (
  <MainList current={current} setCurrent={setCurrent} steps={steps} />
);

function SmallWrapper({ current, setCurrent, steps }: ListProp) {
  const [parsedSteps, setParsedSteps] = useState<Step[]>([]);

  useEffect(() => {
    const siz = Math.min(3, steps.length);
    setParsedSteps(steps.slice(0, siz));
  }, [steps]);

  const setter = (({ idx, id }: Select) => {
    const siz = Math.min(3, steps.length);
    if (id === 0) {
      setParsedSteps(steps.slice(0, siz));
      setCurrent({ idx, id });
    } else if (id === steps.length - 1) {
      setParsedSteps(steps.slice(steps.length - siz, steps.length));
      setCurrent({ idx, id });
    } else {
      const sliced = steps.slice(id - 1, id + 2);
      setParsedSteps(sliced);
      setCurrent({ idx: 1, id });
    }
  }) as React.Dispatch<React.SetStateAction<Select>>;

  return <MainList current={current} setCurrent={setter} steps={parsedSteps} />;
}

export default function Stepper(): ReactElement {
  const [current, setCurrent] = useState({ idx: 0, id: 0 });
  const { width } = useWindowSizeStore();
  const { steps, setStep } = useTLContext();

  const Wrapper = width >= 832 ? BigWrapper : SmallWrapper;

  const set = (({ idx, id }: Select) => {
    setStep(steps[id]);
    setCurrent({ idx, id });
  }) as React.Dispatch<React.SetStateAction<Select>>;

  return (
    <div className="flex justify-center min-h-[120px]">
      <Wrapper current={current} setCurrent={set} steps={steps} />
    </div>
  );
}
