import React, { ReactElement, useEffect, useState } from 'react';
import { Input } from '../../../Input';
import { Select } from '../../../Select';
import { Button } from '../../../Button';
import Modal from 'react-modal';
import { useHireBussinessPlanForm } from '../../../../contexts/HirePlanBussinessFormProvider';
import { useRouteLoaderData } from 'react-router-dom';
import './functionaryForm.css';
import { SaveIcon } from '../../../icons/SaveIcon';
import ImportExcelFunctionary from '../../../../services/importExcel';
import {
  dependenteSchema,
  funcionarioSchema,
} from '../data/schema/functionarysSchema';
import {
  changeCivilStatus,
  changeGender,
  changeIssuingAgency,
  changeParentage,
  changeUF,
} from '../../../../utils/ChangeArrays';
import { DependentForm } from '../DependentsForm/DependentFormTeste';
import { z } from 'zod';
import {
  useFunctionaryAddDataStore,
  useFunctionaryExcelDataStore,
  useFunctionaryIdStore,
  useFunctionaryOpenFormDataStore,
  useFunctionaryOpenModalDataStore,
} from '../../../../stores/useFunctionaryStore';
import { useVidaCountStore } from '../../../../stores/useQuantidadeVidas';
import {
  useDependentIdStore,
  useDependentOpenModalDataStore,
} from '../../../../stores/useDependentStore';

function validateDependent(obj: any): boolean {
  try {
    dependenteSchema.parse(obj);
    return true;
  } catch (error) {
    if (error instanceof z.ZodError) {
      return false;
    }
    return false;
  }
}
function validatefuncionario(obj: any): boolean {
  try {
    funcionarioSchema.parse(obj);
    return true;
  } catch (error) {
    if (error instanceof z.ZodError) {
      return false;
    }
    return false;
  }
}

Modal.setAppElement('#root');

interface FunctionaryFormProps {
  id: number | null;
}

export function FunctionaryForm(): ReactElement {
  const {
    register,
    handleSubmit,
    goToNextForm,
    onSubmit,
    errors,
    watch,
    setValue,
    control,
  } = useHireBussinessPlanForm();
  const initialPlanData: any = useRouteLoaderData('token-empresa');
  const addFuncionary = useFunctionaryAddDataStore(x => x.data);
  const setAddFuncionary = useFunctionaryAddDataStore(
    x => x.setfunctionaryAddData,
  );
  const FuncionaryOpenModal = useFunctionaryOpenModalDataStore(x => x.data);
  const setFuncionaryOpenModal = useFunctionaryOpenModalDataStore(
    x => x.setfunctionaryOpenModalData,
  );

  const setAddExcel = useFunctionaryExcelDataStore(
    x => x.setfunctionaryExcelData,
  );

  const countVida = useVidaCountStore(x => x.count);
  const getFuncId = useFunctionaryIdStore(state => state.id);
  const setFuncId = useFunctionaryIdStore(state => state.setId);
  const setDepId = useDependentIdStore(state => state.setId);
  const [hasDependentes, setHasDependentes] = useState(false);
  const FuncId = getFuncId || 0;

  const setCountVida = useVidaCountStore(x => x.setVidaCount);
  const setDependentOpenModal = useDependentOpenModalDataStore(
    state => state.setdependentOpenModalData,
  );
  const openForm = useFunctionaryOpenFormDataStore(x => x.data);
  const setOpenForm = useFunctionaryOpenFormDataStore(
    x => x.setfunctionaryOpenFormData,
  );

  const CEP = watch(`user.funcionarios.${FuncId}.address.cep`);

  const dependents = watch(`user.funcionarios.${FuncId}.dependentes`);
  useEffect(() => {
    if (dependentes && countVida > 1 && dependentes[0] && dependentes[0].cpf) {
      setHasDependentes(true);
    } else {
      setHasDependentes(false);
    }
  }, [countVida]);

  const getCEPDetails = async (e: string) => {
    const CEPInformations = await fetch(
      `${process.env.REACT_APP_VIACEP}${e}/json/`,
    ).then(response => response.json());

    const UF = initialPlanData.listaUFS.find(
      x => x.sigla == CEPInformations.uf,
    ).Id;
    setValue(
      `user.funcionarios.${FuncId}.address.city`,
      CEPInformations.localidade,
    );
    setValue(
      `user.funcionarios.${FuncId}.address.neighborhood`,
      CEPInformations.bairro,
    );
    setValue(
      `user.funcionarios.${FuncId}.address.street`,
      CEPInformations.logradouro,
    );
    setValue(`user.funcionarios.${FuncId}.address.uf`, String(UF));

    return CEPInformations;
  };
  changeUF(initialPlanData.listaUFS);
  changeGender(initialPlanData.listaSexos);
  changeParentage(initialPlanData.listaParentescos);
  changeIssuingAgency(initialPlanData.listaOrgaosExpedidor);
  changeCivilStatus(initialPlanData.listaEstadosCivil);
  const [data, setData] = useState(initialPlanData);
  const dependentes = watch(`user.funcionarios.${FuncId}.dependentes`);

  const openModal = () => {
    setFuncionaryOpenModal(true);
    setOpenForm(false);
  };

  const closeModal = () => {
    const funcionarios = watch('user.funcionarios');
    funcionarios.forEach((funcionario, index) => {
      const valido = validatefuncionario(funcionario);
      if (!valido) {
        removeFunc(index);
      } else {
        if (funcionario.dependentes) {
          const validaDep = validateDependent(funcionario.dependentes[0]);
          if (!validaDep) {
            removeClick(0);
          }
        }
      }
    });
    setFuncionaryOpenModal(false);
    setAddExcel(true);
    setAddFuncionary(true);
    setOpenForm(false);
  };

  const addFuncionario = async () => {
    const Funcionario = watch(`user.funcionarios.${FuncId}`);
    if (
      Funcionario.dependentes &&
      Funcionario.dependentes[0] &&
      (Funcionario.dependentes[0].cpf == '' ||
        Funcionario.dependentes[0].cpf == undefined)
    ) {
      removeClick(0);
    }
    const validado = validatefuncionario(Funcionario);
    if (validado) {
      const funcionarios = watch('user.funcionarios');
      const countdependentes = funcionarios.reduce((count, funcionario) => {
        return (
          count + (funcionario.dependentes ? funcionario.dependentes.length : 0)
        );
      }, 0);
      const count = funcionarios.length + countdependentes;
      setCountVida(count);
      setAddFuncionary(true);
      setAddExcel(true);
      setOpenForm(false);
      console.log('funcionários: ', funcionarios);
    } else {
      handleSubmit(goToNextForm)();
    }
  };
  const closeFuncionario = () => {
    setAddFuncionary(true);
    setAddExcel(true);
    setOpenForm(false);
  };
  useEffect(() => {
    if (CEP) {
      getCEPDetails(CEP);
    }
  }, [CEP]);

  useEffect(() => {
    setAddFuncionary(true);
    setAddExcel(true);
    setFuncionaryOpenModal(false);
  }, []);
  const addFuncionarioclick = async () => {
    const funcionarios = watch('user.funcionarios');
    const funcionarioId =
      funcionarios && funcionarios[0] && funcionarios[0].nome !== ''
        ? funcionarios.length
        : 0;
    setFuncId(funcionarioId);
    setOpenForm(true);
    setAddFuncionary(false);
    setAddExcel(false);
  };
  const editClick = (index: number) => {
    setDepId(index);
    setDependentOpenModal(true);
  };

  const removeClick = (ind: number) => {
    const currentDependentes =
      watch(`user.funcionarios.${FuncId}.dependentes`) || [];
    const updatedDependentes = currentDependentes.filter(
      (_, index) => index !== ind,
    );
    setValue(`user.funcionarios.${FuncId}.dependentes`, updatedDependentes);
  };
  const removeFunc = (id: number) => {
    const currentFuncionarios = watch(`user.funcionarios`) || [];
    const updatedFuncionarios = currentFuncionarios.filter(
      (_, index) => index !== id,
    );
    setValue(`user.funcionarios`, updatedFuncionarios);
  };

  return (
    <div>
      <div className="w-full min-[748px]:mt-16 mt-24 relative flex min-[748px]:justify-end justify-center gap-6">
        <Button
          type="button"
          text="Adicionar Funcionário"
          onClick={openModal}
        />
      </div>

      <Modal
        isOpen={FuncionaryOpenModal}
        onRequestClose={closeModal}
        contentLabel="Formulário de Funcionário"
        className={openForm ? 'modal' : 'modal-extended'}
        overlayClassName="modal-backdrop"
      >
        <h2 className="min-[748px]:text-xl text-lg font-medium text-primary-main mb-8">
          Dados Funcionário
        </h2>
        <ImportExcelFunctionary />
        {openForm && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid min-[748px]:grid-cols-2 grid-cols-1 gap-6">
              <Input
                label="Nome completo"
                placeholder="Nome completo"
                errorMessage={
                  errors.user?.funcionarios?.[FuncId]?.nome?.message
                }
                {...register(`user.funcionarios.${FuncId}.nome`)}
              />
              <Input
                label="CPF"
                placeholder="CPF"
                activateMask
                mask="999.999.999-99"
                errorMessage={errors.user?.funcionarios?.[FuncId]?.cpf?.message}
                {...register(`user.funcionarios.${FuncId}.cpf`)}
              />
              <Input
                activateMask
                mask="99/99/9999"
                label="Data de nascimento"
                placeholder="Data de nascimento"
                errorMessage={
                  errors.user?.funcionarios?.[FuncId]?.dtNascimento?.message
                }
                {...register(`user.funcionarios.${FuncId}.dtNascimento`)}
              />
              <Input
                label="Nome da mãe"
                placeholder="Nome da mãe"
                errorMessage={
                  errors.user?.funcionarios?.[FuncId]?.motherName?.message
                }
                {...register(`user.funcionarios.${FuncId}.motherName`)}
              />
              <Input
                label="E-mail"
                placeholder="E-mail"
                errorMessage={
                  errors.user?.funcionarios?.[FuncId]?.email?.message
                }
                {...register(`user.funcionarios.${FuncId}.email`)}
              />
              <Input
                label="Matrícula"
                placeholder="Matrícula"
                errorMessage={
                  errors.user?.funcionarios?.[FuncId]?.matricula?.message
                }
                {...register(`user.funcionarios.${FuncId}.matricula`)}
              />
              <Input
                label="RG"
                placeholder="RG"
                errorMessage={errors.user?.funcionarios?.[FuncId]?.rg?.message}
                {...register(`user.funcionarios.${FuncId}.rg`)}
              />
              <Select
                label="Órgão expeditor"
                options={[]}
                optionsFull={data.listaOrgaosExpedidor}
                placeholder="Órgão expeditor"
                errorMessage={
                  errors.user?.funcionarios?.[FuncId]?.orgao?.message
                }
                {...register(`user.funcionarios.${FuncId}.orgao`)}
              />
              <Select
                label="UF órgão expedidor"
                options={[]}
                optionsFull={initialPlanData.listaUFS}
                placeholder="Órgão expedidor UF"
                errorMessage={
                  errors.user?.funcionarios?.[FuncId]?.orgaoUF?.message
                }
                {...register(`user.funcionarios.${FuncId}.orgaoUF`)}
              />
              <Input
                label="CNS- Opcional"
                placeholder="CNS"
                errorMessage={errors.user?.funcionarios?.[FuncId]?.cns?.message}
                {...register(`user.funcionarios.${FuncId}.cns`)}
              />
              <Select
                label="Gênero"
                options={[]}
                optionsFull={initialPlanData.listaSexos}
                placeholder="Gênero"
                errorMessage={
                  errors.user?.funcionarios?.[FuncId]?.sexo?.message
                }
                {...register(`user.funcionarios.${FuncId}.sexo`)}
              />
              <Select
                label="Estado civil"
                options={[]}
                optionsFull={initialPlanData.listaEstadosCivil}
                placeholder="Estado civil"
                errorMessage={
                  errors.user?.funcionarios?.[FuncId]?.estCivil?.message
                }
                {...register(`user.funcionarios.${FuncId}.estCivil`)}
              />
              <Input
                label="Celular"
                activateMask
                mask="(99) 99999-9999"
                placeholder="Telefone"
                errorMessage={
                  errors.user?.funcionarios?.[FuncId]?.Celular?.message
                }
                {...register(`user.funcionarios.${FuncId}.Celular`)}
              />
              <Input
                label="Telefone residencial"
                activateMask
                mask="(99) 9999-9999"
                placeholder="Telefone"
                errorMessage={
                  errors.user?.funcionarios?.[FuncId]?.telefoneRes?.message
                }
                {...register(`user.funcionarios.${FuncId}.telefoneRes`)}
              />
            </div>
            <h2 className="min-[748px]:text-xl text-lg font-medium text-primary-main mb-8 mt-16">
              Endereço do Funcionário
            </h2>
            <div className="grid grid-cols-2 gap-8">
              <Input
                activateMask
                mask="99999-999"
                label="CEP"
                className="w-full"
                placeholder="CEP"
                errorMessage={
                  errors.user?.funcionarios?.[FuncId]?.address?.cep?.message
                }
                {...register(`user.funcionarios.${FuncId}.address.cep`)}
              />
              <Input
                label="Número"
                placeholder="Digite o seu número"
                errorMessage={
                  errors.user?.funcionarios?.[FuncId]?.address?.number?.message
                }
                {...register(`user.funcionarios.${FuncId}.address.number`)}
              />
            </div>
            <div className="grid min-[748px]:grid-cols-1 grid-cols-1 gap-6">
              <Input
                label="Logradouro"
                placeholder="Digite o seu logradouro"
                errorMessage={
                  errors.user?.funcionarios?.[FuncId]?.address?.street?.message
                }
                {...register(`user.funcionarios.${FuncId}.address.street`)}
              />
              <div className="grid grid-cols-2 gap-8">
                <Input
                  label="Complemento"
                  placeholder="Digite o seu complemento"
                  errorMessage={
                    errors.user?.funcionarios?.[FuncId]?.address?.complement
                      ?.message
                  }
                  {...register(
                    `user.funcionarios.${FuncId}.address.complement`,
                  )}
                />
                <Input
                  label="Cidade"
                  placeholder="Digite sua cidade"
                  errorMessage={
                    errors.user?.funcionarios?.[FuncId]?.address?.city?.message
                  }
                  {...register(`user.funcionarios.${FuncId}.address.city`)}
                />
              </div>
              <div className="grid grid-cols-3 gap-8">
                <Input
                  label="Bairro"
                  placeholder="Digite o seu bairro"
                  errorMessage={
                    errors.user?.funcionarios?.[FuncId]?.address?.neighborhood
                      ?.message
                  }
                  {...register(
                    `user.funcionarios.${FuncId}.address.neighborhood`,
                  )}
                />
                <Select
                  label="UF"
                  options={[]}
                  optionsFull={initialPlanData.listaUFS}
                  placeholder="UF"
                  errorMessage={
                    errors.user?.funcionarios?.[FuncId]?.address?.uf?.message
                  }
                  {...register(`user.funcionarios.${FuncId}.address.uf`)}
                />
              </div>
            </div>
            {hasDependentes && (
              <div className="w-full min-[748px]:mt-4 rounded-xl p-2">
                <table width="98%" style={{ margin: '2%' }}>
                  <thead>
                    <tr>
                      <th style={{ textAlign: 'center' }}>Nome</th>
                      <th style={{ textAlign: 'center' }}>CPF</th>
                      <th style={{ textAlign: 'center' }}>Nascimento</th>
                      <th style={{ textAlign: 'center' }}>Parentesco</th>
                      <th style={{ textAlign: 'center' }}>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dependentes?.map((dependente, index) => (
                      <React.Fragment key={index}>
                        <tr>
                          <td style={{ textAlign: 'center' }}>
                            {dependente.nome}
                          </td>
                          <td style={{ textAlign: 'center' }}>
                            {dependente.cpf}
                          </td>
                          <td style={{ textAlign: 'center' }}>
                            {dependente.dtNascimento}
                          </td>
                          <td style={{ textAlign: 'center' }}>
                            {dependente.parentesco != ''
                              ? data.listaParentescos.find(
                                  x => x.Id == dependente.parentesco,
                                )?.Sigla
                              : ''}
                          </td>
                          <td style={{ textAlign: 'center' }}>
                            <button
                              type="button"
                              onClick={() => editClick(index)}
                            >
                              Editar
                            </button>
                            {' / '}
                            <button
                              type="button"
                              onClick={() => removeClick(index)}
                            >
                              Remover
                            </button>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            <DependentForm />
          </form>
        )}
        {addFuncionary && (
          <Button
            type="button"
            onClick={addFuncionarioclick}
            text="Adicionar Funcionário"
          />
        )}
        {openForm && (
          <div
            style={{
              display: 'flex',
              marginTop: '25px',
              flexDirection: 'row-reverse',
            }}
          >
            <Button
              type="button"
              onClick={closeFuncionario}
              text="Cancelar"
              style={{ background: '#CC0505' }}
            />
            <Button
              type="button"
              intent="secondary"
              Icon={SaveIcon}
              onClick={addFuncionario}
              text="salvar Funcionário"
              style={{ marginRight: '20px' }}
            />
          </div>
        )}
        <div
          style={{
            display: 'flex',
            marginTop: '25px',
            flexDirection: 'row-reverse',
          }}
        >
          <Button type="button" onClick={closeModal} text="Fechar" />
        </div>
      </Modal>
    </div>
  );
}

export default FunctionaryForm;
