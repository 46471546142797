import type { ReactElement } from 'react';

import FaqList from '../../../components/FaqList';
import QuestionForm from './QuestionForm';

const FaqSection = (): ReactElement => (
  <section className="min-[1300px]:mt-10 min-[1300px]:mb-52">
    <div className="flex max-[1000px]:flex-col min-[1300px]:gap-32 items-start max-w-[100%] overflow-hidden">
      <div className="mix-[1300px]:pl-20 px-5 z-10">
        <FaqList />
        <div className="invisible max-[999px]:hidden">
          <p>t</p>
        </div>
      </div>
    </div>
  </section>
);

export default FaqSection;
