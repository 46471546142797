import * as z from 'zod';

export const newDependentsSchema = z.object({
  name: z.optional(z.string().min(1, { message: 'Campo obrigatório' })),
  birthDate: z
    .string()
    .min(1, { message: 'Campo obrigatório' })
    .regex(/^(\d{2})\/(\d{2})\/(\d{4})$/, { message: 'Data inválida' }),
  civilStatus: z.optional(z.string().min(1, { message: 'Campo obrigatório' })),
  gender: z.optional(z.string().min(1, { message: 'Campo obrigatório' })),
  parentage: z.optional(z.string().min(1, { message: 'Campo obrigatório' })),
  cpf: z.optional(
    z
      .string()
      .min(14, { message: 'Requer 14 caracteres' })
      .regex(/^(?!\d{11})\d{3}\.?\d{3}\.?\d{3}-?\d{2}$/, {
        message: 'CPF inválido',
      }),
  ),
  mothersName: z.optional(z.string().min(1, { message: 'Campo obrigatório' })),
  plano: z.optional(z.string()),
  plano_vl: z.optional(z.string()),
});
