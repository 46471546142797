import { FC, forwardRef, InputHTMLAttributes } from 'react';
import InputMask, { Props as InputMaskProps } from 'react-input-mask';

import clsx from 'clsx';

interface Props
  extends InputHTMLAttributes<HTMLInputElement>,
    Partial<InputMaskProps> {
  label: string;
  activateMask?: boolean;
  textArea?: boolean;
  errorMessage?: string;
}

export const Input: FC<Props> = forwardRef<HTMLInputElement, Props>(
  (
    {
      label,
      className,
      activateMask = false,
      mask = '',
      errorMessage,
      textArea,
      ...props
    },
    ref,
  ) => {
    const ParentElement = (
      activateMask ? InputMask : textArea ? 'textarea' : 'input'
    ) as typeof InputMask | 'input';

    return (
      <>
        <fieldset className="flex flex-col gap-1 w-full">
          <label htmlFor={label} className="text-high-emphasis">
            {label}
          </label>
          <ParentElement
            ref={ref}
            mask={mask}
            id={label}
            className={clsx(
              `w-full placeholder:text-medium-emphasis px-4 py-3 rounded-lg text-high-emphasis border  focus:ring-1 focus:outline-none ${className}`,
              errorMessage
                ? 'border-destructive-main focus:ring-destructive-main'
                : 'border-neutral-lighter focus:ring-primary-main',
              textArea && 'resize-none',
            )}
            {...props}
          />

          {errorMessage && (
            <span className="text-xs text-destructive-dark mt-1">
              {errorMessage}
            </span>
          )}
        </fieldset>
      </>
    );
  },
);
