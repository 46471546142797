import { ReactElement } from 'react';

import { useDentistsContext } from '..';

interface Props {
  total: number;
}

export default function InfoBox({ total }: Props): ReactElement {
  const { state, city, specialty, plan } = useDentistsContext();
  return (
    <div className="bg-primary-container2 rounded-lg shadow-card p-5 mt-5">
      <div className="flex justify-between mt-3 max-[1300px]:flex-col min-[1300px]:items-center gap-8">
        <div className="flex gap-8 max-[1300px]:flex-col">
          <div>
            <p className="font-bold uppercase text-medium-emphasis text-base">
              Região
            </p>
            <p className="text-high-emphasis text-2xl">
              {(city as any).Nome} - {state.Sigla}
            </p>
          </div>
          <div>
            <p className="font-bold uppercase text-medium-emphasis text-base">
              Especialidade
            </p>
            <p className="text-high-emphasis text-2xl">
              {specialty.nomeEspecialidade}
            </p>
          </div>
          <div>
            <p className="font-bold uppercase text-medium-emphasis text-base">
              Rede Plano
            </p>
            <p className="text-high-emphasis text-2xl">{plan.name}</p>
          </div>
        </div>

        <div className="flex flex-col items-end w-fit">
          <p className="uppercase text-medium-emphasis text-xs">
            Total de dentistas encontrados
          </p>
          <p className="text-high-emphasis font-[500] text-sm">{total}</p>
        </div>
      </div>
    </div>
  );
}
