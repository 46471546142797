import React, { useEffect } from 'react';

const GaleriaRedirect: React.FC = () => {
  useEffect(() => {
    // Redireciona para o site externo
    window.location.href = 'http://lp.prod.odontogroup.com.br/galeria-2025';
  }, []);

  return null; // Não renderiza nada
};

export default GaleriaRedirect;
