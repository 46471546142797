import type { ReactElement } from 'react';

const StepsSection = (): ReactElement => (
  <section className="flex justify-evenly h-[300px] max-[1000px]:flex-col">
    <div className="bg-primary-main w-[33%] flex flex-col justify-center p-10 max-[1000px]:w-full">
      <h3 className="text-4xl font-semibold text-neutral-with max-[1000px]:text-2xl">
        Passo 1
      </h3>
      <p className="text-2xl text-[#E2E4FD] max-[1000px]:text-lg">
        Escolha a melhor solução.
      </p>
    </div>
    <div className="bg-new-blue w-[34%] flex flex-col justify-center p-10 max-[1000px]:w-full">
      <h3 className="text-4xl font-semibold text-primary-dark max-[1000px]:text-2xl">
        Passo 2
      </h3>
      <p className="text-2xl text-primary-main max-[1000px]:text-lg">
        Contrate seu plano odonto online, sem burocracia.
      </p>
    </div>
    <div className="bg-new-green w-[33%] flex flex-col justify-center p-10 max-[1000px]:w-full">
      <h3 className="text-4xl font-semibold text-primary-dark max-[1000px]:text-2xl">
        Passo 3
      </h3>
      <p className="text-2xl text-primary-main max-[1000px]:text-lg">
        Agora, é só aproveitar as vantagens de ser um usuário Odontogroup.
      </p>
    </div>
  </section>
);

export default StepsSection;
