export interface State {
  Id: number;
  Sigla: string;
  Descricao: string;
}

export interface City {
  Id: number;
  Sigla: string;
  Descricao: string;
}

const domain: { states?: State[] } = {
  states: undefined,
};

export async function getStates(): Promise<State[]> {
  if (domain.states) return domain.states;
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_GET_STATES}?token=${process.env.REACT_APP_TOKEN_S4E}`,
    ).then(response => response.json());
    const filteredStates = resp.dados.filter((state: State) => state.Id !== 0);

    domain.states = filteredStates;
    return resp.dados;
  } catch (err) {
    console.log(err);
    return [];
  }
}

export const getCities = async (ufID: number | string): Promise<City[]> => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_GET_CITIES}?token=${
        process.env.REACT_APP_TOKEN_S4E
      }&ufID=${ufID}&apenasRedeCredenciada=${1}`,
    ).then(response => response.json());
    return resp.dados;
  } catch (err) {
    console.log(err);
    return [];
  }
};
