import { create } from 'zustand';

type ResponseState = {
  response: any;
  setResponse: (response: any) => void;
};

export const useResponseStore = create<ResponseState>((set, get) => ({
  response: {},
  setResponse: data => set(() => ({ response: data })),
  action: () => {
    const response = get().response;
  },
}));
