import type { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { Button } from '../../../components/Button';
import { ArrowRightIcon } from '../../../components/icons/ArrowRightIcon';
import SpaceCard from './SpaceCard';
import styles from './spaces.module.css';

interface SpaceProps {
  left?: boolean;
  title: string;
  card1Text: string;
  card1Icon: string;
  card1Link: string;
  card2Text: string;
  card2Icon: string;
  card2Link: string;
  midText: string;
  btnText: string;
}

const Space = ({
  left = false,
  title,
  card1Text,
  card1Icon,
  card1Link,
  card2Text,
  card2Icon,
  card2Link,
  midText,
  btnText,
}: SpaceProps) => (
  <div className={left ? styles.leftSide : styles.rightSide}>
    <h3 className="text-center font-bold text-2xl text-primary-main mb-10 max-[1259px]:text-lg mt-5">
      {title}
    </h3>
    <div className="flex gap-5 max-[900px]:flex-col max-[1259px]:justify-center max-[1259px]:items-center">
      <SpaceCard text={card1Text} icon={card1Icon} href={card1Link} />
      <SpaceCard text={card2Text} icon={card2Icon} href={card2Link} />
    </div>
    <p className="text-high-emphasis text-2xl text-center mt-5 min-[1260px]:relative min-[1260px]:top-10">
      {midText}
    </p>
    <Button
      invertIcon
      tag={Link}
      to="/quero-contratar"
      intent="newOrange"
      Icon={ArrowRightIcon}
      text={btnText}
      className="mx-auto -bottom-[1rem] min-[1260px]:-bottom-[4rem] relative w-fit"
    />
  </div>
);

const SpacesSection = (): ReactElement => (
  <section className="mt-10 mb-32 min-[1260px]:px-10 px-5">
    <div className={styles.container}>
      <Space
        left
        title="Espaço do Beneficiário"
        card1Text="Pessoa Física"
        card1Icon="user.png"
        card1Link={process.env.REACT_APP_S4E_ASSOCIADO}
        card2Text="Pessoa Jurídica"
        card2Icon="company.png"
        card2Link={process.env.REACT_APP_S4E_EMPRESA}
        midText="Ainda não tem um plano?"
        btnText="Quero ser Cliente"
      />

      <Space
        title="Espaço do Parceiro"
        card1Text="Prestador"
        card1Icon="dentist.png"
        card1Link={process.env.REACT_APP_DENTISTA}
        card2Text="Corretor"
        card2Icon="business.png"
        card2Link={process.env.REACT_APP_CORRETOR}
        midText="Ainda não é um parceiro?"
        btnText="Quero ser parceiro"
      />
    </div>
  </section>
);

export default SpacesSection;
