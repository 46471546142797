import { ReactElement } from 'react';

import { useHirePlanFormStepStore } from '../../../stores/useHirePlanFormStepStore';
import { ServidorValidationForm } from './Dependent-include/steps/ServidorValidationForm';
import { BeneficiaryForm } from './Dependent-include/steps/DependentsForm';
import { AceiteForm } from './Dependent-include/steps/AceiteForm';
import { ConfirmForm } from './Dependent-include/steps/ConfirmForm';
import { useRouteLoaderData } from 'react-router-dom';

export function UpdateDependentFormStepHandler(): ReactElement {
  const step = useHirePlanFormStepStore(state => state.step);
  //const data: any = useRouteLoaderData('token');
  //console.log(data);

  /* if (
    data.produtoComercial.id_modproduto_c == 1 ||
    data.produtoComercial.id_modproduto_c == 2
  ) { */
  //OdontoClinico e OdontoDOC tem turbine o seu plano
  switch (step) {
    case 1: {
      return <ServidorValidationForm />;
    }

    case 2: {
      return <BeneficiaryForm />;
    }

    case 3: {
      return <AceiteForm />;
    }

    case 4: {
      return <ConfirmForm />;
    }

    default: {
      return <ServidorValidationForm />;
    }
  }
  /*   } else {
    switch (step) {
      case 1: {
        return <BeneficiaryRegisterForm />;
      }

      case 2: {
        return <DependentsForm />;
      }

      case 3: {
        return <PaymentMethodForm />;
      }

      default: {
        return <BeneficiaryRegisterForm />;
      }
    }
  } */
}
