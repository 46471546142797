import { ReactElement, useState } from 'react';

import { cva } from 'class-variance-authority';

import { NewPersonalPlans } from '../../../components/NewPersonalPlans';
import { businessPlans } from '../../../components/NewPersonalPlans/data/plans';
import { useWindowSizeStore } from '../../../stores/useWindowSizeStore';
import CompanyPlanForm from './CompanyPlanForm';

const firstList = businessPlans.filter(
  p => p.category[0].type === '2 a 29 Vidas',
);

const secondList = businessPlans.filter(
  p => p.category[0].type === '30 a 199 Vidas',
);

const selectedDiv = cva(
  'flex text-lg border-b px-4 gap-2 text-neutral-with cursor-pointer border-neutral-lighter max-[1299px]:text-sm max-[1299px]:px-2',
  {
    variants: {
      state: {
        selected: 'font-bold border-b-2 border-new-orange text-new-orange',
        default: 'font-medium',
      },
    },
    defaultVariants: {
      state: 'default',
    },
  },
);

const Selectable = ({
  active,
  onClick,
  text,
  Icon,
}: {
  active: boolean;
  onClick: any;
  text: string;
  Icon?: any;
}) => (
  <div
    className={selectedDiv({
      state: active ? 'selected' : 'default',
    })}
    onClick={onClick}
  >
    {Icon && <Icon color={active ? '#E6007E' : '#5C6770'} />}
    <p>{text}</p>
  </div>
);

function PlanList({ width }: { width: number }): ReactElement {
  const [list, setList] = useState<'first' | 'second' | 'third'>('first');

  const planList = list === 'first' ? firstList : secondList;

  return (
    <>
      <div className="w-fit mx-auto flex">
        <Selectable
          active={list === 'first'}
          text={width >= 1300 ? 'Planos para 2 até 29 vidas' : '2 até 29 vidas'}
          onClick={() => setList('first')}
        />
        <Selectable
          active={list === 'second'}
          text={
            width >= 1300 ? 'Planos para 30 até 199 vidas' : '30 até 199 vidas'
          }
          onClick={() => setList('second')}
        />
        <Selectable
          active={list === 'third'}
          text={width >= 1300 ? 'Planos para mais de 200 vidas' : '200+ vidas'}
          onClick={() => setList('third')}
        />
      </div>
      {list !== 'third' ? (
        <NewPersonalPlans
          className="!w-fit"
          customList={planList}
          type="business"
        />
      ) : (
        <CompanyPlanForm />
      )}
    </>
  );
}

export default function CompanyPlans(): ReactElement {
  const { width } = useWindowSizeStore();

  return (
    <section
      className="max-[1300px]:mb[88rem] bg-new-darkblue mt-11 pb-10 pt-20"
      id="company-contracts"
    >
      <div className="mx-auto text-center max-w-[990px] mb-10">
        <h2 className="min-[1548px]:text-[2.625rem] min-[701px]:text-[2rem] text-lg text-primary-container font-semibold mb-3 mx-auto">
          Planos para seu time
        </h2>
        <p className="min-[1548px]:text-2xl min-[701px]:text-xl text-base text-new-blue  font-normal">
          Conheça nossos planos e convênios, escolha a opção que se encaixa com
          o perfil da sua empresa.
        </p>
      </div>
      <PlanList width={width} />
    </section>
  );
}
