import { FC, ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';

import clsx from 'clsx';
import { motion, Variants } from 'framer-motion';

import { CaretDownIcon } from '../../icons/CaretDownIcon';

interface Props {
  item: {
    name: string;
    href?: string;
    Icon: FC<{ className?: string; width: string; height: string }>;
    subPages?: {
      name: string;
      href: string;
    }[];
  };
}

const variants: Variants = {
  open: { height: 'auto' },
  closed: { height: 0 },
};

export function MenuItem({ item }: Props): ReactElement {
  const [isOpen, setIsOpen] = useState(false);

  function toggleMenuItem() {
    if (item.subPages) {
      setIsOpen(prevState => !prevState);
    }
  }

  const ParentElement = item.subPages ? 'button' : Link;

  return (
    <li className="stroke-neutral-dark text-high-emphasis">
      <ParentElement
        onClick={toggleMenuItem}
        to={item.href as string}
        className="w-full px-6 py-3 border-b border-b-neutral-lightest flex items-center justify-between"
      >
        <div className="flex gap-2 items-center">
          <item.Icon width="20" height="20" />
          <span>{item.name}</span>
        </div>
        {item.subPages && (
          <CaretDownIcon className={clsx(isOpen && 'rotate-180')} />
        )}
      </ParentElement>
      <motion.ul
        initial={false}
        className="overflow-hidden"
        animate={isOpen && item.subPages ? 'open' : 'closed'}
        variants={variants}
      >
        {item.subPages?.map(subPage => (
          <li
            key={subPage.name}
            className=" border-b border-b-neutral-lightest w-full"
          >
            <Link to={subPage.href} className="px-10 py-3 w-full block">
              {subPage.name}
            </Link>
          </li>
        ))}
      </motion.ul>
    </li>
  );
}
