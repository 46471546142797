import { ReactElement, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useRouteLoaderData } from 'react-router-dom';
import Toggle from 'react-toggle';

import { useHirePlanForm } from '../../../../contexts/HirePlanFormProvider';
import { getPlanDetails } from '../../../../requests/requests';
import { useBoostedPlanDataStore } from '../../../../stores/useBoostedPlanDataStore';
import { useError } from '../../../../stores/useError';
import { useLoadingStore } from '../../../../stores/useLoadingStore';
import { useNortheastPlanDataStore } from '../../../../stores/useNortheastPlanDataStore';
import { useWindowSizeStore } from '../../../../stores/useWindowSizeStore';
import {
  Id_Products,
  IndividualWithShortageTokens,
  IndividualWithShortageNorthEastTokens,
} from '../../../../utils/Enums/Tokens';
import { Button } from '../../../Button';
import { CartIcon } from '../../../icons/CartIcon';
import Loading from '../../../icons/Loading';
import { ErrorModal } from '../../../Modal/Modal';
import { GoForwardButton } from '../../GoForwardButton';
import { FinancialResponsibleForm } from '../FinancialResponsibleForm';
import { PaymentSelector } from './PaymentSelector';

async function fetchPrice(token: string): Promise<string> {
  try {
    const response = await fetch(
      process.env.REACT_APP_OG_GET_PLAN_DETAILS_INDIVIDUAL + token,
    );
    const data = await response.json();
    return data.produtoComercial?.formasPagamentoIndividual[0]?.vl_valor;
  } catch (error) {
    console.error('Não foi possível obter o preço', error);
    return 'Preço não disponível';
  }
}

export function PaymentMethodForm(): ReactElement {
  const { handleSubmit, onSubmit } = useHirePlanForm();
  const { control } = useForm();
  const individualPlanData: any = useRouteLoaderData('token');
  const [anual, setAnual] = useState(false);
  const setBoostPlanData = useBoostedPlanDataStore(
    state => state.setBoostPlanData,
  );
  const boostedPlanData = useBoostedPlanDataStore(state => state.data);
  const [data, setData] = useState(individualPlanData);
  const width = useWindowSizeStore(state => state.width);

  const loading = useLoadingStore(state => state.loading);
  const error = useError(state => state.error);
  const title = useError(state => state.title);
  const text = useError(state => state.text);
  const produtosAnuais = Object.values(Id_Products).filter(
    value => typeof value === 'number',
  ) as number[];
  const northeastPlanData = useNortheastPlanDataStore(state => state.data);
  const planInitial =
    boostedPlanData == undefined
      ? data?.produtoComercial?.id_prodcomerc
      : boostedPlanData.produtoComercial?.id_prodcomerc;
  const [plano, setPlano] = useState(planInitial);
  useEffect(() => {
    if (boostedPlanData != undefined) {
      setData(boostedPlanData);
      setPlano(boostedPlanData.produtoComercial?.id_prodcomerc);
    }
  }, [boostedPlanData]);

  useEffect(() => {
    let newData = {};
    const loadPrice = async (plan: number) => {
      let newToken: string | undefined = undefined;
      switch (plan) {
        case Id_Products.CLINICO_NA:
        case Id_Products.CLINICO_NA_AN: {
          if (anual) {
            newToken = IndividualWithShortageTokens.CLINICO_AN;
            newData = await getPlanDetails(newToken);
            setBoostPlanData(newData);
          } else {
            newToken = IndividualWithShortageTokens.CLINICO;
            newData = await getPlanDetails(newToken);
            setBoostPlanData(newData);
          }
          break;
        }
        case Id_Products.CLINICO_NE:
        case Id_Products.CLINICO_NE_AN: {
          if (anual) {
            newToken = IndividualWithShortageNorthEastTokens.CLINICO_AN;
            newData = await getPlanDetails(newToken);
            setBoostPlanData(newData);
          } else {
            newToken = IndividualWithShortageNorthEastTokens.CLINICO;
            newData = await getPlanDetails(newToken);
            setBoostPlanData(newData);
          }
          break;
        }
        case Id_Products.DOC_NA:
        case Id_Products.DOC_NA_AN: {
          if (anual) {
            newToken = IndividualWithShortageTokens.DOC_AN;
            newData = await getPlanDetails(newToken);
            setBoostPlanData(newData);
          } else {
            newToken = IndividualWithShortageTokens.DOC;
            newData = await getPlanDetails(newToken);
            setBoostPlanData(newData);
          }
          break;
        }
        case Id_Products.PROTESE_NA:
        case Id_Products.PROTESE_NA_AN: {
          if (anual) {
            newToken = IndividualWithShortageTokens.PROTESE_AN;
            newData = await getPlanDetails(newToken);
            setBoostPlanData(newData);
          } else {
            newToken = IndividualWithShortageTokens.PROTESE;
            newData = await getPlanDetails(newToken);
            setBoostPlanData(newData);
          }
          break;
        }
      }
    };
    loadPrice(plano);
  }, [anual]);

  const AnualChange = () => {
    setAnual(!anual);
  };

  return (
    <form
      className="flex flex-col items-center max-w-[755px] w-full"
      onSubmit={handleSubmit(onSubmit)}
    >
      {error && <ErrorModal open={true} title={title} text={text} />}
      <FinancialResponsibleForm />
      {width >= 748 && (
        <>
          <h2 className="text-[2rem] font-semibold text-primary-main mt-12">
            Forma de pagamento
          </h2>
          {produtosAnuais.includes(plano) && (
            <div className="flex min-[748px]:items-center gap-6 min-[748px]:px-8 py-3 justify-center">
              <span className="block text-neutral-700 font-semibold">
                Mensal
              </span>
              <label className="flex">
                <Controller
                  control={control}
                  name="Payment.Type"
                  render={({ field }) => (
                    <Toggle
                      defaultChecked={field.value}
                      onChange={AnualChange}
                      icons={false}
                      className="custom-classname"
                      //ref={field.ref}
                    />
                  )}
                />
              </label>
              <span className="block text-neutral-700 font-semibold">
                Anual
              </span>
            </div>
          )}
          <p className="font-normal text-secondary-main mt-1 text-center w-11/12">
            Escolha uma forma de pagamento. Um link será enviado para o seu
            email para finalizar a compra.
          </p>
        </>
      )}
      <PaymentSelector />
      <GoForwardButton
        disabled={loading}
        addBackButton
        Icon={loading ? Loading : CartIcon}
        desktopText={loading ? '' : 'Finalizar compra'}
        mobileText={loading ? '' : 'Finalizar compra'}
      />
    </form>
  );
}
