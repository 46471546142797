import type { ReactElement } from 'react';

import { Button } from './Button';
import { DownloadIcon } from '../../components/icons/DownloadIcon';

interface Props {
  title: string;
  info?: string | null;
  href: string;
}

const DownloadBox = ({ title, info = null, href }: Props): ReactElement => (
  <div className="flex shadow-card rounded-xl w-full items-center p-4 justify-between max-w-[704px] gap-10 max-[799px]:flex-col max-[799px]:text-center max-[799px]:gap-4 bg-white">
    <div>
      <h4 className="text-2xl text-primary-main max-[799px]:text-lg max-[799px]:font-bold max-[799px]:max-w-[312px] max-[799px]:mx-auto">
        {title}
      </h4>
      {info && (
        <h6 className="text-xl text-medium-emphasis max-[799px]:text-base">
          {info}
        </h6>
      )}
    </div>
    <Button
      tag="a"
      href={href}
      target="_blank"
      text="Download"
      className="max-[799px]:w-full"
      intent="outlined"
      Icon={DownloadIcon}
      iconProps={{ color: '#EE6C4D' }}
    />
  </div>
);

export default DownloadBox;
