import { ReactElement } from 'react';

export const PhoneIcon: () => ReactElement = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.3333 1H25M25 1V7.66667M25 1L17 9M3.66667 1C2.95942 1 2.28115 1.28095 1.78105 1.78105C1.28095 2.28115 1 2.95942 1 3.66667V5C1 16.0453 9.95467 25 21 25H22.3333C23.0406 25 23.7189 24.719 24.219 24.219C24.719 23.7189 25 23.0406 25 22.3333V17.96C24.9998 17.6803 24.9116 17.4077 24.7479 17.1809C24.5843 16.954 24.3534 16.7844 24.088 16.696L18.0973 14.6987C17.7929 14.5975 17.4621 14.6094 17.1658 14.7324C16.8695 14.8553 16.6274 15.081 16.484 15.368L14.9773 18.3773C11.7124 16.9019 9.09749 14.2866 7.62267 11.0213L10.632 9.51733C10.919 9.37394 11.1447 9.13187 11.2676 8.83553C11.3906 8.53919 11.4025 8.20845 11.3013 7.904L9.304 1.91067C9.21531 1.64531 9.04539 1.41457 8.8183 1.25114C8.59121 1.0877 8.31846 0.99984 8.03867 1H3.66667Z"
      stroke="#616AC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
