import { ReactElement, useEffect, useState } from 'react';

import { useHirePlanForm } from '../../../contexts/HirePlanFormProvider';
import { Input } from '../../Input';
import { Select } from '../../Select';
import { GoForwardButton } from '../GoForwardButton';
import { useWindowSizeStore } from '../../../stores/useWindowSizeStore';
import { useBoostedPlanDataStore } from '../../../stores/useBoostedPlanDataStore';
import { useRouteLoaderData } from 'react-router-dom';
import { PlanBoost } from '../../../utils/Enums/PlanBoostEnum';
import {
  IndividualWithShortageNorthEastTokens,
  IndividualWithShortageTokens,
  IndividualWithoutShortageTokens,
} from '../../../utils/Enums/Tokens';
import { getPlanDetails } from '../../../requests/requests';
import { useNortheastPlanDataStore } from '../../../stores/useNortheastPlanDataStore';

export function PlanBoostForm(): ReactElement {
  const { register, handleSubmit, goToNextForm, errors } = useHirePlanForm();
  const setBoostPlanData = useBoostedPlanDataStore(
    state => state.setBoostPlanData,
  );

  const width = useWindowSizeStore(state => state.width);

  const northeastPlanData = useNortheastPlanDataStore(state => state.data);
  let newData = {};

  useEffect(() => {
    if (northeastPlanData.produtoComercial.id_modproduto_c == 5) {
      setBoostPlanData(northeastPlanData);
      handleSubmit(goToNextForm)();
    }
  }, []);

  async function handleClick(plan: string) {
    let newToken: string | undefined = undefined;
    switch (plan) {
      case PlanBoost.DOC: {
        if (northeastPlanData.northeast == 1) {
          newToken = IndividualWithShortageNorthEastTokens.DOC;
          newData = await getPlanDetails(newToken);
          setBoostPlanData(newData);
        } else {
          newToken = IndividualWithShortageTokens.DOC;
          newData = await getPlanDetails(newToken);
          setBoostPlanData(newData);
        }
        break;
      }
      case PlanBoost.ORTO: {
        if (northeastPlanData.northeast == 1) {
          newToken = IndividualWithShortageNorthEastTokens.ORTO;
          newData = await getPlanDetails(newToken);
          setBoostPlanData(newData);
        } else {
          newToken = IndividualWithShortageTokens.ORTO;
          newData = await getPlanDetails(newToken);
          setBoostPlanData(newData);
        }
        break;
      }
      case PlanBoost.PROTESE: {
        if (northeastPlanData.northeast == 1) {
          newToken = IndividualWithShortageNorthEastTokens.PROTESE;
          newData = await getPlanDetails(newToken);
          setBoostPlanData(newData);
        } else {
          newToken = IndividualWithShortageTokens.PROTESE;
          newData = await getPlanDetails(newToken);
          setBoostPlanData(newData);
        }

        break;
      }
      case PlanBoost.NENHUM: {
        setBoostPlanData(northeastPlanData);
        break;
      }
    }
  }

  return (
    <form
      action=""
      className="max-w-[766px] w-full"
      onSubmit={handleSubmit(goToNextForm)}
    >
      {width >= 748 && (
        <>
          <h2 className="text-[2rem] font-semibold text-center text-primary-main">
            Turbine seu plano
          </h2>
          <p className="font-normal text-secondary-main mt-1 text-center mb-8">
            Selecione uma opção para deixar seu plano do jeito que você precisa
          </p>
        </>
      )}
      {northeastPlanData.produtoComercial.id_modproduto_c == 1 ? ( //OdontoClinico
        <div className="grid grid-cols-1 gap-2">
          <div className="flex w-full min-h-[80px]">
            <div className="flex rounded-l-md bg-neutral-off-white px-4 py-4 w-full">
              <div className="grid grid-cols-1 gap-8 content-center">
                <Input
                  className="w-[20px] h-[20px]"
                  label=""
                  type="radio"
                  value={PlanBoost.DOC}
                  onClick={a => handleClick(a.currentTarget.value)}
                  //errorMessage={errors.fullName?.message}
                  {...register('payment.name')}
                />
              </div>
              <span className="flex-1 text-info-dark text-sm ml-4 self-center font-medium">
                Adicionar cobertura para documentação e aparelho convencional
                por mais
                <span className="font-bold"> R$4,10</span>/mês ?
              </span>
            </div>
            <div className="bg-secondary-main w-8 h-full rounded-r-md"></div>
          </div>

          <div className="flex w-full min-h-[80px]">
            <div className="flex rounded-l-md bg-neutral-off-white px-4 py-4 w-full">
              <div className="grid grid-cols-1 gap-8 content-center">
                <Input
                  className="w-[20px] h-[20px]"
                  label=""
                  type="radio"
                  value={PlanBoost.ORTO}
                  onClick={a => handleClick(a.currentTarget.value)}
                  //errorMessage={errors.fullName?.message}
                  {...register('payment.name')}
                />
              </div>
              <span className="flex-1 text-info-dark text-sm ml-4 self-center font-medium">
                Adicionar manutenções mensais e clareamento após tratamento
                ortodôntico por mais
                <span className="font-bold"> R$103,10</span>/mês ?
              </span>
            </div>
            <div className="bg-secondary-main w-8 h-full rounded-r-md"></div>
          </div>
          <div className="flex w-full min-h-[80px]">
            <div className="flex rounded-l-md bg-neutral-off-white px-4 py-4 w-full">
              <div className="grid grid-cols-1 gap-8 content-center">
                <Input
                  className="w-[20px] h-[20px]"
                  label=""
                  type="radio"
                  value={PlanBoost.PROTESE}
                  onClick={a => handleClick(a.currentTarget.value)}
                  //errorMessage={errors.fullName?.message}
                  {...register('payment.name')}
                />
              </div>
              <span className="flex-1 text-info-dark text-sm ml-4 self-center font-medium">
                Adicionar clareamento convencional por mais
                <span className="font-bold"> R$63,10</span>/mês ?
                <span className="font-normal block">
                  + Próteses total, próteses removível e placa de bruxismo
                </span>
              </span>
            </div>
            <div className="bg-secondary-main w-8 h-full rounded-r-md"></div>
          </div>
          <div className="flex w-full min-h-[80px]">
            <div className="flex rounded-l-md bg-neutral-off-white px-4 py-4 w-full">
              <div className="grid grid-cols-1 gap-8 content-center">
                <Input
                  className="w-[20px] h-[20px]"
                  label=""
                  type="radio"
                  value={PlanBoost.NENHUM}
                  onClick={a => handleClick(a.currentTarget.value)}
                  //errorMessage={errors.fullName?.message}
                  {...register('payment.name')}
                />
              </div>
              <span className="flex-1 text-info-dark text-sm ml-4 self-center font-medium">
                Não quero turbinar meu plano
              </span>
            </div>
            <div className="bg-secondary-main w-8 h-full rounded-r-md"></div>
          </div>
        </div>
      ) : northeastPlanData.produtoComercial.id_modproduto_c == 4 ? ( //OdontoDOC
        <div className="grid grid-cols-1 gap-2">
          <div className="flex w-full min-h-[80px]">
            <div className="flex rounded-l-md bg-neutral-off-white px-4 py-4 w-full">
              <div className="grid grid-cols-1 gap-8 content-center">
                <Input
                  className="w-[20px] h-[20px]"
                  label=""
                  type="radio"
                  value={PlanBoost.ORTO}
                  onClick={a => handleClick(a.currentTarget.value)}
                  //errorMessage={errors.fullName?.message}
                  {...register('payment.name')}
                />
              </div>
              <span className="flex-1 text-info-dark text-sm ml-4 self-center font-medium">
                Adicionar manutenções mensais e clareamento após tratamento
                ortodôntico por mais
                <span className="font-bold"> R$99,00</span>/mês ?
              </span>
            </div>
            <div className="bg-secondary-main w-8 h-full rounded-r-md"></div>
          </div>
          <div className="flex w-full min-h-[80px]">
            <div className="flex rounded-l-md bg-neutral-off-white px-4 py-4 w-full">
              <div className="grid grid-cols-1 gap-8 content-center">
                <Input
                  className="w-[20px] h-[20px]"
                  label=""
                  type="radio"
                  value={PlanBoost.PROTESE}
                  onClick={a => handleClick(a.currentTarget.value)}
                  //errorMessage={errors.fullName?.message}
                  {...register('payment.name')}
                />
              </div>
              <span className="flex-1 text-info-dark text-sm ml-4 self-center font-medium">
                Adicionar clareamento convencional por mais
                <span className="font-bold"> R$59,00</span>/mês ?
                <span className="font-normal block">
                  + Próteses total, próteses removível e placa de bruxismo
                </span>
              </span>
            </div>
            <div className="bg-secondary-main w-8 h-full rounded-r-md"></div>
          </div>
          <div className="flex w-full min-h-[80px]">
            <div className="flex rounded-l-md bg-neutral-off-white px-4 py-4 w-full">
              <div className="grid grid-cols-1 gap-8 content-center">
                <Input
                  className="w-[20px] h-[20px]"
                  label=""
                  type="radio"
                  value={PlanBoost.NENHUM}
                  onClick={a => handleClick(a.currentTarget.value)}
                  //errorMessage={errors.fullName?.message}
                  {...register('payment.name')}
                />
              </div>
              <span className="flex-1 text-info-dark text-sm ml-4 self-center font-medium">
                Não quero turbinar meu plano
              </span>
            </div>
            <div className="bg-secondary-main w-8 h-full rounded-r-md"></div>
          </div>
        </div>
      ) : (
        northeastPlanData.produtoComercial.id_modproduto_c == 2 && ( //OdontoOrto
          <div className="grid grid-cols-1 gap-2">
            <div className="flex w-full min-h-[80px]">
              <div className="flex rounded-l-md bg-neutral-off-white px-4 py-4 w-full">
                <div className="grid grid-cols-1 gap-8 content-center">
                  <Input
                    className="w-[20px] h-[20px]"
                    label=""
                    type="radio"
                    value={PlanBoost.NENHUM}
                    onClick={a => handleClick(a.currentTarget.value)}
                    defaultChecked={true}
                    //errorMessage={errors.fullName?.message}
                    {...register('payment.name')}
                  />
                </div>
                <span className="flex-1 text-info-dark text-sm ml-4 self-center font-medium">
                  Não quero turbinar meu plano
                </span>
              </div>
              <div className="bg-secondary-main w-8 h-full rounded-r-md"></div>
            </div>
          </div>
        )
      )}
      <GoForwardButton addBackButton />
    </form>
  );
}
