import { ReactElement } from 'react';

import { PageHelmet } from '../../components/PageHelmet';
import VideoBanner from '../../components/VideoBanner';
import Banner from './Banner';
// import InfoSection from './InfoSection';
import SocialSection from './SocialSection';
import StepsSection from './StepsSection';
import TimelineSection from './TimelineSection';

export default function AboutPage(): ReactElement {
  return (
    <div className="h-full min-h-[calc(100vh-549px)]">
      <PageHelmet title="Quem Somos | OdontoGroup" />

      <Banner
        title="Quem Somos"
        page="about"
        mainText="Um cuidado que vale mais que 1000 sorrisos..."
      />
      <VideoBanner page="about" />
      <StepsSection />
      <TimelineSection />
      <SocialSection />
      {/* <InfoSection /> */}
    </div>
  );
}
