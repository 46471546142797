import type { ReactElement } from 'react';

const SocialSection = (): ReactElement => (
  <section className="bg-cover bg-odonto-social max-[1000px]:bg-[60%] min-[1001px]:bg-[60%_30%] w-full h-[693px] p-24 max-[1000px]:p-5 flex items-end">
    <div className="max-w-[1283px]">
      <h2 className="text-primary-container2 font-semibold text-[2.625rem] max-[748px]:text-2xl">
        Odonto Social
      </h2>
      <h4 className="text-neutral-with text-2xl">
        A solidariedade transforma vidas
      </h4>

      <p className="text-white text-2xl mt-10 max-[748px]:text-base">
        Nosso compromisso com o bem-estar e qualidade de vida vai além. Todos os
        anos, desenvolvemos e participamos de diversos projetos que promovem o
        cuidado preventivo e rotinas educacionais por todos os estados em que
        atuamos.
      </p>
    </div>
  </section>
);

export default SocialSection;
