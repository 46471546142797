import { ReactElement, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

import jStates from '../../assets/json/states.json';
import { Button } from '../../components/Button';
import { EditIcon } from '../../components/icons/EditIcon';
import { Input } from '../../components/Input';
import { Select } from '../../components/Select';

const indicationFormSchema = z.object({
  name: z.string().min(1, { message: 'Campo obrigatório' }),
  phone: z.optional(z.string()),
  state: z.string().min(1, { message: 'Campo obrigatório' }),
  city: z.string().min(1, { message: 'Campo obrigatório' }),
});

type IndicationFormSchema = z.infer<typeof indicationFormSchema>;

const url = `${process.env.REACT_APP_OG_API_URL}/indication`;
export default function IndicationForm(): ReactElement {
  const { register, handleSubmit, formState, setValue } =
    useForm<IndicationFormSchema>({
      defaultValues: {},
      resolver: zodResolver(indicationFormSchema),
    });

  const { errors } = formState;
  const [resp, setResp] = useState<{
    done: boolean;
    error: boolean;
    msg: string;
  }>({} as any);
  const [load, setLoad] = useState(false);

  function submitForm(event) {
    event.preventDefault();
    handleSubmit(async data => {
      try {
        setLoad(true);
        const resp = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });
        const body = await resp.json();

        setResp({
          done: true,
          error: false,
          msg: body.message ?? 'Indicação Enviada Com Sucesso!!!',
        });
      } catch (err) {
        //
        console.log(err);
        setResp({
          done: true,
          error: true,
          msg: 'Erro ao enviar mensagem verifique os campos e tente novamente.',
        });
      } finally {
        setLoad(false);
      }
    })();
  }

  const states = useMemo(() => jStates.estados.map(s => s.sigla), []);

  const [cities, setCities] = useState<string[]>([]);

  function onStateChange(event) {
    const state = event.target.value;
    const foundState = jStates.estados.find(s => s.sigla === state);
    setCities(foundState?.cidades ?? []);
    setValue('city', undefined as any);
  }

  return (
    <form id="ouvidoria-form" onSubmit={submitForm}>
      <div className="flex flex-col gap-8 mt-8">
        <div className="grid grid-cols-1 gap-16">
          <Input
            label="Nome do(a) Dentista/Clínica"
            placeholder="Nome"
            errorMessage={errors.name?.message}
            {...register('name')}
          />
        </div>
        <div className="grid grid-cols-1 gap-16">
          <Input
            label="Telefone com DDD"
            placeholder="(00) 00000-0000"
            activateMask
            mask={'(99) 99999-9999'}
            errorMessage={errors.phone?.message}
            {...register('phone')}
          />
        </div>

        <div className="grid min-[748px]:grid-cols-3 grid-cols-1 gap-8">
          <Select
            label="Estado"
            options={states}
            placeholder="Estado"
            errorMessage={errors.state?.message}
            {...register('state', { onChange: onStateChange })}
          />
          <div className="min-[748px]:col-span-2">
            <Select
              label="Cidade"
              options={cities}
              placeholder="Cidade"
              errorMessage={errors.city?.message}
              {...register('city')}
            />
          </div>
        </div>
      </div>
      {resp.done && (
        <p
          className={`mt-1 mb-2 text-base ${
            !resp.error ? 'text-success-main' : 'text-destructive-main'
          }`}
        >
          {resp.msg}
        </p>
      )}
      <Button
        disabled={load}
        className="min-[748px]:ml-auto max-[748px]:w-full mt-6"
        text="Enviar Indicação"
        intent="primary"
        Icon={EditIcon}
        type="submit"
      />
    </form>
  );
}
