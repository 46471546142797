import type { ReactElement } from 'react';

import DentistSearch from '../../components/DentistSearch';

const SearchSection = (): ReactElement => (
  <section>
    <img
      src="/assets/images/dentists/dentist.png"
      className="w-full brightness-50 max-[748px]:h-[577px] max-[748px]:object-cover"
    />
    <div className="-mt-60 z-10 relative max-[748px]:-mb-44">
      <h3 className="min-[1548px]:text-[2.625rem] min-[1548px]:leading-[3.75rem] min-[701px]:text-[2rem] text-lg max-w-[1124px] mb-10 text-white font-semibold mx-auto text-center max-[999px]:mx-10">
        Encontre um dentista parceiro Odontogroup pertinho de você
      </h3>
      <DentistSearch />
    </div>
  </section>
);

export default SearchSection;
