import { ReactElement } from 'react';

import { useWindowSizeStore } from '../../stores/useWindowSizeStore';
import { Button } from '../Button';
import { DownloadIcon } from '../icons/DownloadIcon';
import { InformationSectionProposal } from './InformationSectionProposal';
import { PaymentSectionProposal } from './PaymentSectionProposal';
import { PaymentSummaryProposal } from './PaymentSummaryProposal';
import { Link, useNavigate } from 'react-router-dom';
import { useHirePlanFormStepStore } from '../../stores/useHirePlanFormStepStore';

type Props = {
  data: {
    linkProposta: string;
    numeroProposta: string;
    dataCadastro: string;
    dataVencimento: string;
    dataVigencia?: string;
    nomeVendedor: string;
    nome: string;
    email: string;
    ddd: string;
    telefone: string;
    nomePlano: string;
    quantidadeVidas: string;
    valorPagamento: string;
    paymentStatus: number;
    formaPagamento: string;
    grupoPagamento: number;
    linkPagamento: string;
  };
};

export function ProposalArticle(state: Props): ReactElement {
  const navigate = useNavigate();
  const width = useWindowSizeStore(state => state.width);

  return (
    <article className="flex relative overflow-hidden max-w-[1492px] w-full mx-auto h-full min-h-[751px] shadow-card min-[748px]:-mt-[321px] -mt-[53px] mb-20 bg-white rounded-3xl">
      {width >= 1524 && (
        <div className="max-w-[622px] w-full h-full bg-proposal relative min-h-[751px]">
          <img
            src="/assets/images/proposal-top-frame.svg"
            className="absolute -left-16 -top-0  h-[558px]"
            alt=""
          />
          <img
            src="/assets/images/proposal-bottom-frame.svg"
            className="absolute -bottom-52 -right-60 bg-no-repeat min-h-[751px]"
            alt=""
          />
        </div>
      )}
      <div className="w-full min-h-[751px] h-full z-10 flex justify-center items-center p-6">
        <div className="max-w-[766px]  w-full h-full">
          <div className="flex min-[748px]:justify-between justify-center mb-14">
            <h1 className="text-primary-main font-semibold min-[748px]:text-[2rem] text-2xl">
              Proposta #{state.data.numeroProposta}
            </h1>
            {width >= 748 && (
              <Link to={state.data.linkProposta} target="_blank">
                <Button
                  text="Baixar proposta"
                  intent="tertiary"
                  Icon={DownloadIcon}
                />
              </Link>
            )}
          </div>
          <InformationSectionProposal data={state.data} />
          <PaymentSummaryProposal data={state.data} />
          <PaymentSectionProposal data={state.data} />
          {width < 748 && (
            <div className="w-full flex justify-center mt-8">
              <Link to={state.data.linkProposta} target="_blank">
                <Button
                  text="Baixar proposta"
                  intent="tertiary"
                  Icon={DownloadIcon}
                />
              </Link>
            </div>
          )}
        </div>
      </div>
    </article>
  );
}
