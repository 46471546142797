import { FC, ReactElement } from 'react';

import { useHirePlanFormStepStore } from '../../stores/useHirePlanFormStepStore';
import { useWindowSizeStore } from '../../stores/useWindowSizeStore';
import { Button } from '../Button';
import { ArrowLeftIcon } from '../icons/ArrowLeftIcon';
import { ArrowRightIcon } from '../icons/ArrowRightIcon';

interface Props {
  addBackButton?: boolean;
  onForwardClick?: () => void;
  mobileText?: string;
  disabled?: boolean;
  desktopText?: string;
  Icon?: FC;
  validate?: () => boolean;
  tipo?: 'button' | 'reset' | 'submit';
}

export function GoForwardButton({
  addBackButton = false,
  onForwardClick,
  disabled = false,
  mobileText = 'Avançar',
  desktopText = 'Continuar',
  Icon,
  validate,
  tipo,
}: Props): ReactElement {
  const width = useWindowSizeStore(state => state.width);
  const goToPreviousStep = useHirePlanFormStepStore(
    state => state.goToPreviousStep,
  );

  const isMobile = width < 748;

  const handleForwardClick = () => {
    if (validate && !validate()) {
      return;
    } else {
      onForwardClick();
    }
  };

  return (
    <div className="w-full min-[748px]:mt-16 mt-24 relative flex min-[748px]:justify-end justify-center gap-6">
      {!isMobile && addBackButton && (
        <Button
          text="Voltar"
          Icon={ArrowLeftIcon}
          intent="secondary"
          onClick={goToPreviousStep}
        />
      )}
      <Button
        disabled={disabled}
        type={tipo}
        onClick={handleForwardClick}
        text={isMobile ? mobileText : desktopText}
        Icon={Icon ?? (isMobile ? undefined : ArrowRightIcon)}
        className="max-[748px]:max-w-[262px] max-[748px]:w-full"
      />
    </div>
  );
}
