import { ReactElement } from 'react';

interface Props {
  className?: string;
}

export const ChevronRight: (p: Props) => ReactElement = ({ className }) => (
  <svg
    className={className}
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5 7.5L24 18L13.5 28.5"
      stroke="#272D65"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
