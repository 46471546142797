import type { ReactElement } from 'react';

import QuickActions from '../../components/QuickActions';

const items = [
  {
    text: 'Rede de Atendimento NACIONAL',
    icon: 'brazil.png',
    href: '/rede-credenciada',
  },
  {
    text: 'Serviço de Concierge Exclusivo',
    icon: 'customer-service.png',
  },
  {
    text: 'Soluções Personalizadas',
    icon: 'tech.png',
    subText: 'Produtos sob medida para cada beneficiário',
  },
  {
    text: 'Rede de Vantagens e Descontos',
    icon: 'promo.png',
  },
  {
    text: 'Planos SEM LIMITE de uso',
    icon: 'career.png',
  },
  {
    text: 'Saiba Mais',
    icon: 'plus.png',
    href: '/quero-contratar',
    reversed: true,
  },
];

const ActionsSection1 = (): ReactElement => (
  <section className="mt-10 mb-32">
    <h2 className="text-2xl text-center">
      Com a Odontogroup você tem muito mais:
    </h2>
    <QuickActions items={items} />
  </section>
);

export default ActionsSection1;
