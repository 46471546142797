import { ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

import { Button } from '../../components/Button';
import { EditIcon } from '../../components/icons/EditIcon';
import { Input } from '../../components/Input';

const ouvidoriaFormSchema = z.object({
  name: z.string().min(1, { message: 'Campo obrigatório' }),
  phone: z.optional(z.string()),
  subject: z.string().min(1, { message: 'Campo obrigatório' }),
  email: z
    .string()
    .min(1, { message: 'Campo obrigatório' })
    .email({ message: 'email inválido' }),
  protocol: z.string().min(1, { message: 'Campo obrigatório' }),
  message: z.string().min(1, { message: 'Campo obrigatório' }),
});

type OuvidoriaFormSchema = z.infer<typeof ouvidoriaFormSchema>;

const url = `${process.env.REACT_APP_OG_API_URL}/ouvidoria`;
export default function OuvidoriaForm(): ReactElement {
  const { register, handleSubmit, formState } = useForm<OuvidoriaFormSchema>({
    defaultValues: {},
    resolver: zodResolver(ouvidoriaFormSchema),
  });

  const { errors } = formState;
  const [resp, setResp] = useState<{
    done: boolean;
    error: boolean;
    msg: string;
  }>({} as any);
  const [load, setLoad] = useState(false);

  function submitForm(event) {
    event.preventDefault();
    handleSubmit(async data => {
      try {
        setLoad(true);
        const resp = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });
        const body = await resp.json();

        setResp({
          done: true,
          error: false,
          msg: body.message ?? 'Mensagem Enviada Com Sucesso!!!',
        });
      } catch (err) {
        setResp({
          done: true,
          error: true,
          msg: 'Erro ao enviar mensagem verifique os campos e tente novamente.',
        });
      } finally {
        setLoad(false);
      }
    })();
  }

  return (
    <form id="ouvidoria-form" onSubmit={submitForm}>
      <div className="flex flex-col gap-8 mt-8">
        <div className="grid grid-cols-1 gap-16">
          <Input
            label="Nome"
            placeholder="Nome"
            errorMessage={errors.name?.message}
            {...register('name')}
          />
        </div>

        <div className="grid min-[748px]:grid-cols-2 grid-cols-1 min-[748px]:gap-16 gap-8">
          <Input
            label="E-mail"
            type="email"
            placeholder="sample@email.com"
            errorMessage={errors.email?.message}
            {...register('email')}
          />
          <Input
            label="Telefone"
            placeholder="(00) 00000-0000"
            activateMask
            mask={'(99) 99999-9999'}
            errorMessage={errors.phone?.message}
            {...register('phone')}
          />
        </div>

        <div className="grid grid-cols-1 gap-16">
          <Input
            label="Assunto"
            placeholder="Assunto"
            errorMessage={errors.subject?.message}
            {...register('subject')}
          />
        </div>

        <div className="grid grid-cols-1 gap-16">
          <Input
            label="Protocolo Central de Atendimento"
            maxLength={20}
            placeholder="Protocolo Central de Atendimento"
            errorMessage={errors.protocol?.message}
            {...register('protocol')}
          />
        </div>

        <div className="grid min-[748px]:grid-cols-1 grid-cols-1 gap-16 w-full">
          <Input
            label="Mensagem"
            textArea={true}
            placeholder="Detalhar situação"
            errorMessage={errors.message?.message}
            {...register('message')}
          />
        </div>
      </div>
      {resp.done && (
        <p
          className={`mt-1 mb-2 text-base ${
            !resp.error ? 'text-success-main' : 'text-destructive-main'
          }`}
        >
          {resp.msg}
        </p>
      )}
      <Button
        disabled={load}
        className="min-[748px]:ml-auto max-[748px]:w-full mt-4"
        text="Registrar Mensagem"
        intent="primary"
        Icon={EditIcon}
        type="submit"
      />
    </form>
  );
}
