import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import BigBanner from '../../components/BigBanner';
import { Button } from '../../components/Button';
import { ArrowRightIcon } from '../../components/icons/ArrowRightIcon';
import { PageHelmet } from '../../components/PageHelmet';
import FaleConoscoForm from './FaleConoscoForm';

export default function FaleConoscoPage(): ReactElement {
  return (
    <div className="h-full min-h-[calc(100vh-549px)]">
      <PageHelmet title="Fale Conosco | OdontoGroup" />

      <BigBanner
        href="#ouvidoria-form"
        page="ouvidoria"
        mainText="Para registrar um chamado no fale conosco, é necessário um contato através do nosso canal"
      />

      <section className="min-h-[300px] max-[748px]:block">
        <div className="min-[1000px]:flex items-center justify-between -mb-32 pl-20 pr-20 max-[1400px]:p-5">
          <div className="max-w-[762px]">
            <p className="font-[400] min-[1400px]:text-[1.5rem] leading-9 text-medium-emphasis">
              O Fale Conosco é um canal de comunicação independente e imparcial,
              criado para ser a voz dos nossos clientes junto à nossa Operadora.
              Este espaço foi desenvolvido para receber seus comentários,
              elogios, sugestões e reclamações sobre nossos serviços e
              benefícios, com o objetivo de promover a melhoria contínua na
              qualidade do atendimento.
            </p>
            <br />
            <br />
          </div>
          <img
            className="max-[748px]:hidden"
            src="/assets/images/ouvidoria.png"
            alt="Sorriso"
          />
        </div>
      </section>

      <section>
        <img
          className="hidden max-[748px]:block z-2 -mb-[15rem]"
          src="/assets/images/ouvidoria.png"
          alt="Sorriso"
        />
      </section>

      <section className="bg-primary-main min-h-[938px] justify-between max-[748px]:z-1"></section>

      <section className="max-[748px]:z-3 flex min-[748px]:gap-28 items-center min-[748px]:p-12 p-1 -mt-[50rem] max-[1400px]:flex-col max-[1400px]:bg-primary-main justify-center gap-3 pr-12 pl-12 max-[748px]:pr-5 max-[748px]:pl-5 max-[748px]:pb-24">
        <div className="bg-white max-h-[40%] rounded p-10 max-[748px]:p-5 min-[748px]:min-w-[676px] shadow-card">
          <h3 className="text-primary-main font-semibold text-[2rem] max-[1000px]:text-[1.125rem] max-w-[500px]">
            Registrar um Fale Conosco
          </h3>
          <FaleConoscoForm />
        </div>

        <div>
          <div>
            <p className="text-neutral-with text-2xl leading-9 font-[400] max-[748px]:text-base">
              Agora você, nosso beneficiário, pode registrar seu “fale conosco”
              acessando sua área exclusiva através do site{' '}
              <a
                href="https://odontogroup.s4e.com.br/sys/?TipoUsuario=1"
                className="font-bold"
              >
                clicando aqui
              </a>{' '}
              ou registrar diretamente em nosso sistema de atendimento usando o
              formulário ao lado.
            </p>

            <br />

            <p className="text-neutral-with text-2xl leading-9 font-[400] max-[748px]:text-base">
              Caso você seja uma Pessoa Jurídica, um Corretor ou um Prestador,
              acesse sua área exclusiva clicando no ícone abaixo que o
              identifique e registre um “fale conosco” diretamente em nosso
              sistema. O formulário ao lado é exclusivo para nosso beneficiário
              pessoa física.
            </p>

            <div className="flex gap-4 mt-4">
              <Button
                tag={Link}
                to="https://odontogroup.s4e.com.br/sys/?TipoUsuario=2"
                text="Pessoa Juridica"
                className="max-[900px]:mx-auto w-fit"
                intent="newOrange"
              />
              <Button
                tag={Link}
                to="https://odontogroup.s4e.com.br/sys/?TipoUsuario=6"
                text="Corretor"
                className="max-[900px]:mx-auto w-fit"
                intent="newOrange"
              />
              <Button
                tag={Link}
                to="https://odontogroup.s4e.com.br/sys/?TipoUsuario=4"
                text="Prestador"
                className="max-[900px]:mx-auto w-fit"
                intent="newOrange"
              />
            </div>
          </div>

          <p className="text-neutral-with text-2xl leading-9 font-bold mt-4 mb-3 max-[748px]:text-center max-[748px]:text-lg">
            Onde Baixar:
          </p>

          <div className="flex max-[748px]:flex-col gap-8 max-[748px]:items-center">
            <a
              className="max-w-[50%]"
              href={process.env.REACT_APP_GOOGLE_PLAY}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/assets/images/google-play-tag.png"
                alt="Tag GooglePlay"
              />
            </a>
            <a
              className="max-w-[50%]"
              href={process.env.REACT_APP_APPLE_STORE}
              target="_blank"
              rel="noreferrer"
            >
              <img src="/assets/images/app-store-tag.png" alt="Tag AppStore" />
            </a>
          </div>
        </div>
      </section>

      <section className="min-[748px]:min-h-[300px]" />
    </div>
  );
}
