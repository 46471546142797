import * as z from 'zod';

export const validaSchema = z.object({
  validation: z.optional(
    z.object({
      cpf: z.optional(
        z
          .string()
          .min(14, { message: 'Campo obrigatório' })
          .regex(/^(?!\d{11})\d{3}\.?\d{3}\.?\d{3}-?\d{2}$/, {
            message: 'CPF inválido',
          }),
      ),
      matricula: z.optional(
        z.string().min(1, { message: 'Campo obrigatório' }),
      ),
      retorno: z.object({
        cep: z.string().optional().nullable(),
        complemento: z.string().optional().nullable(),
        cpf: z.string().optional(),
        data_nasc: z.string().optional().nullable(),
        email: z.string().optional().nullable(),
        nome: z.string().optional().nullable(),
        nome_mae: z.string().optional().nullable(),
        numero: z.preprocess(val => {
          if (val === '') return null;
          if (val != null) return Number(val);
          return val;
        }, z.number().optional().nullable()),
        sexo: z.string().optional().nullable(),
        telefone: z.string().optional().nullable(),
      }),
    }),
  ),
});
export type ValidaSchema = z.infer<typeof validaSchema>;
