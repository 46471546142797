import { ReactElement } from 'react';

import { useDentistsContext } from '.';
import DentistSearchContainer from './DentistSearchContainer';
import MapStage from './MapStage';
import SearchStage from './SearchStage';

export default function Search(): ReactElement {
  const { found, reset } = useDentistsContext();

  return (
    <DentistSearchContainer showSearch={found} reset={reset}>
      {found ? <MapStage /> : <SearchStage />}
    </DentistSearchContainer>
  );
}
