import React, { ReactElement, useState } from 'react';
import * as XLSX from 'xlsx';
import { Input } from '../components/Input';
import {
  funcionarioSchema,
  Funcionario,
  Dependente,
} from '../components/HirePlanForm/Bussiness-steps/data/schema/functionarysSchema';
import { useHireBussinessPlanForm } from '../contexts/HirePlanBussinessFormProvider';
import { Button } from '../components/Button';
import {
  useFunctionaryAddDataStore,
  useFunctionaryExcelDataStore,
  useFunctionaryOpenModalDataStore,
} from '../stores/useFunctionaryStore';
import { useRouteLoaderData } from 'react-router-dom';
import { useVidaCountStore } from '../stores/useQuantidadeVidas';
import { HelpCircle } from 'lucide-react';
import { Tooltip } from 'react-tooltip';

export function ImportExcelFunctionary(): ReactElement {
  const { register, handleSubmit, goToNextForm, errors, watch, setValue } =
    useHireBussinessPlanForm();
  const data: any = useRouteLoaderData('token-empresa');
  const [utiliza, setUtiliza] = useState<boolean>(false);
  const addExcel = useFunctionaryExcelDataStore(x => x.data);
  const setAddExcel = useFunctionaryExcelDataStore(
    x => x.setfunctionaryExcelData,
  );
  const setAddFunctionary = useFunctionaryAddDataStore(
    x => x.setfunctionaryAddData,
  );
  const setFunctionaryopenModal = useFunctionaryOpenModalDataStore(
    x => x.setfunctionaryOpenModalData,
  );
  const Vidas = useVidaCountStore(x => x.count);
  const setCountVida = useVidaCountStore(x => x.setVidaCount);

  const [excelData, setExcelData] = useState<Funcionario[]>([]);

  const handleNUtiliza = () => {
    setUtiliza(false);
    setAddFunctionary(true);
  };

  const handleUtiliza = () => {
    setUtiliza(true);
    setAddFunctionary(false);
  };

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = async event => {
        const binaryStr = event.target?.result;
        if (binaryStr) {
          try {
            const workbook = XLSX.read(binaryStr, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json<any>(worksheet);

            const [processedData, validationError] = await processExcelData(
              jsonData,
            );

            if (validationError) {
              console.error('Erro de validação:', validationError);
            } else {
              setExcelData(processedData);
            }
          } catch (error: any) {
            console.error('Erro ao processar o arquivo. ', error);
            alert('Erro ao processar o arquivo. ' + error);
          }
        }
      };

      reader.readAsBinaryString(file);
    }
  };

  const validateFuncionario = (funcionario: Funcionario): string | null => {
    try {
      funcionarioSchema.parse(funcionario);
      return null;
    } catch (e: any) {
      return e.errors.map((err: any) => err.message).join(', ');
    }
  };
  function excelDateToJSDate(serial) {
    const date = new Date(1900, 0, serial - 1);
    return date.toLocaleDateString();
  }
  const getCEPDetails = async (
    e: string,
    number: string,
    complemento: string,
  ) => {
    const CEPInformations = await fetch(
      `${process.env.REACT_APP_VIACEP}${e}/json/`,
    ).then(response => response.json());
    if (CEPInformations.status === 500 || CEPInformations.status === 404) {
      throw new Error('Not Found');
    } else if (CEPInformations.erro) {
      throw new Error(`Cep não encontrado: ${e}`);
    }
    const UF = data.listaUFS.find(x => x.sigla == CEPInformations.uf).Id;
    const address = {
      cep: String(e || ''),
      neighborhood: CEPInformations.bairro,
      uf: String(UF),
      city: CEPInformations.localidade,
      street: CEPInformations.logradouro,
      number: String(number || ''),
      complement: String(complemento || ''),
    };

    return address;
  };

  const processExcelData = async (
    jsonData: any[],
  ): Promise<[Funcionario[], string | null]> => {
    const funcionarios: Funcionario[] = [];
    let currentFuncionario: Funcionario | null = null;
    let error: string | null = null;

    for (const row of jsonData) {
      if (row.Tipo === 1) {
        if (currentFuncionario) {
          const validationError = validateFuncionario(currentFuncionario);
          if (validationError) {
            error = validationError;
          } else {
            funcionarios.push(currentFuncionario);
          }
        }

        const address = await getCEPDetails(
          row.CEP,
          row.numero,
          row.complemento,
        );
        currentFuncionario = {
          nome: String(row.Nome || ''),
          cpf: String(row.CPF || ''),
          dtNascimento: String(excelDateToJSDate(row.dataNascimento) || ''),
          matricula: String(row.matricula || ''),
          rg: String(row.RG || ''),
          orgao: '63',
          orgaoUF: '8',
          UF: String(row.UF || ''),
          cns: String(row.cns || ''),
          sexo: String(row.sexo || ''),
          estCivil: String(row.estadoCivil || ''),
          telefoneRes: String(row.telefoneRes || null),
          Celular: String(row.Celular || ''),
          email: String(row.Email || ''),
          motherName: String(row.nomeMae || ''),
          dependentes: [],
          address: address,
        };
      } else if (row.Tipo === 2 && currentFuncionario) {
        const dependente: Dependente = {
          nome: String(row.Nome || ''),
          cpf: String(row.CPF || ''),
          dtNascimento: String(excelDateToJSDate(row.dataNascimento) || ''),
          rg: String(row.RG || ''),
          orgao: '63',
          orgaoUF: '8',
          cns: String(row.cns || ''),
          parentesco: String(row.parentesco || ''),
          sexo: String(row.sexo || ''),
          estCivil: String(row.estadoCivil || ''),
          motherName: String(row.nomeMae || ''),
          email: String(row.Email || ''),
        };
        currentFuncionario.dependentes?.push(dependente);
      }
    }

    if (currentFuncionario) {
      const validationError = validateFuncionario(currentFuncionario);
      if (validationError) {
        error = validationError;
        throw new Error(error);
      } else {
        funcionarios.push(currentFuncionario);
      }
    }

    return [funcionarios, error];
  };

  const onSubmit = () => {
    if (excelData.length > 0) {
      const funcionarios = watch('user.funcionarios') || [];
      const newFuncionarios = [...funcionarios, ...excelData];
      setValue('user.funcionarios', newFuncionarios);
      let count = Vidas;
      count = count + excelData.length;
      const countdependentes = excelData.reduce((count, funcionario) => {
        return (
          count + (funcionario.dependentes ? funcionario.dependentes.length : 0)
        );
      }, 0);
      count = count + countdependentes;
      setCountVida(count);
      setFunctionaryopenModal(false);
      setAddExcel(true);
      setAddFunctionary(true);
    } else {
      throw new Error('Nenhum dado do Excel foi carregado.');
    }
  };
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = '/assets/documentos/layout.xlsx';
    link.setAttribute('download', 'layout.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      {utiliza ? (
        <>
          <h2 className="min-[748px]:text-xl text-lg font-medium text-primary-main mb-8">
            Importar Planilha de Funcionários e Dependentes
          </h2>
          <Input
            label="Excel"
            type="file"
            accept=".xlsx, .xls"
            onChange={handleFileUpload}
          />
          <div
            style={{
              marginTop: '20px',
              marginBottom: '20px',
              display: 'flex',
              flexDirection: 'row-reverse',
            }}
          >
            <Button
              text="Cancelar"
              onClick={handleNUtiliza}
              style={{ background: '#CC0505' }}
            />
            <Button
              text="Enviar"
              onClick={onSubmit}
              style={{ marginRight: '20px', background: '#4CAF50' }}
            />
          </div>
        </>
      ) : (
        <>
          {addExcel && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '20px',
              }}
            >
              <div>
                <Button text="Importar via Excel" onClick={handleUtiliza} />
              </div>
              <div>
                <div style={{ marginLeft: '90%', marginBottom: '5%' }}>
                  <a
                    data-tooltip-id="download-tooltip"
                    data-tooltip-content="Guia de preenchimento do Layout"
                    href="/assets/documentos/Manual_preenchimento_Planilha_Inclusao.pdf"
                    download="documentacao.pdf"
                    className="text-blue-500 cursor-pointer"
                  >
                    <HelpCircle size={18} />
                  </a>
                  <Tooltip id="download-tooltip" place="top" />
                </div>
                <Button
                  text="Baixar Layout do Excel"
                  onClick={handleDownload}
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default ImportExcelFunctionary;
