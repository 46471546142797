import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { footerNavigation } from './data/footerNavigation';
import { socialMedia } from './data/socialMedia';
import { FooterInformationText } from './FooterInformationText';

export function Footer(): ReactElement {
  function handleScrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <footer className="min-[1512px]:h-[549px] bg-[#0C0F22] relative overflow-hidden flex flex-col">
      <div className="flex justify-center pt-20">
        <button
          onClick={handleScrollToTop}
          className="min-[880px]:absolute right-8 top-8"
        >
          <img
            src="/assets/images/icons/scroll-top.svg"
            alt="Ir para o topo da página"
          />
        </button>
      </div>

      <div className="max-[1512px]:py-20 min-[1512px]:w-[1464px] px-6 min-[1512px]:h-[472px] flex max-[880px]:flex-col min-[1512px]:gap-[120px] gap-8 max-[1512px]:justify-between items-center mx-auto">
        <div className="flex flex-col justify-center gap-7">
          <img
            src="/assets/logos/odonto-footer.png"
            alt="Logo OdontoGroup"
            style={{ height: '110px' }}
          />
          <ul className="flex justify-center gap-3">
            {socialMedia.map(item => (
              <li key={item.name}>
                <Link to={item.link} target="_blank">
                  <img src={item.src} alt={item.name} />
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <ul className="flex flex-col gap-6 max-[1512px]:text-sm min-w-[10rem]">
          {footerNavigation.map(item => (
            <li
              key={item.name}
              className="text-neutral-100 underline max-[880px]:text-center"
            >
              <Link to={item.link}>{item.name}</Link>
            </li>
          ))}
        </ul>
        <div className="min-[1512px]:h-[292px] min-[1512px]:w-[482px] border border-neutral-100 text-neutral-100 rounded-xl flex flex-col justify-center">
          <div className="p-6 flex flex-col justify-center gap-4 flex-wrap">
            <FooterInformationText
              title="Telefones úteis e horário de atendimento"
              description="Segunda à sexta-feira 8h às 18h"
              detail="(horário   Brasília)"
            />
            <div className="flex gap-8">
              <FooterInformationText
                title="Whatsapp"
                description="(61) 4007 1087"
              />
              <FooterInformationText
                title="Pernambuco"
                description="️(81) 3031 4141"
              />
            </div>
            <div className="flex gap-8">
              <FooterInformationText
                title="Distrito Federal"
                description="(61) 3421 0000"
              />
              <FooterInformationText
                title="Capitais e Regiões Metropolitanas"
                description="️4007 1087"
              />
            </div>
            <FooterInformationText
              title="Demais Localidades"
              description="️0800 704 3663"
            />
          </div>
        </div>
        <div className="flex flex-col gap-8 max-[880px]:items-center">
          <img
            src="/assets/logos/sinog.png"
            alt=""
            className="w-[82px] h-[94px] object-contain"
          />
          <a href="https://www.gov.br/ans/pt-br">
            <img
              src="/assets/logos/ans.png"
              alt=""
              className="w-[162px] h-[32px] object-contain"
            />
          </a>
          <a href="https://www.gov.br/ans/pt-br">
            <img
              src="/assets/logos/ans-code.png"
              alt=""
              className="w-[179px] h-[49px] object-contain"
            />
          </a>
        </div>
      </div>
      <div className="h-[77px] border-t border-t-white flex justify-center items-center">
        <span className="text-xl text-[#FCFCFC]">
          Odontogroup © {new Date().getFullYear()}.
        </span>
      </div>
    </footer>
  );
}
