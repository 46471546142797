import { ReactElement, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useMobileMenuStore } from '../../stores/useMobileMenuStore';
import { useScreenStore } from '../../stores/useScreenStore';
import { Button } from '../Button';
import { CartIcon } from '../icons/CartIcon';
import { MenuIcon } from '../icons/MenuIcon';
import { MobileMenu } from './MobileMenu';
import { Navbar } from './Navbar';
import { HashLink } from 'react-router-hash-link';

export function Header(): ReactElement {
  const { xl } = useScreenStore(state => state.screen);
  const toggleMenu = useMobileMenuStore(state => state.toggleMenu);

  useEffect(() => {
    const embedScript = document.createElement('script');
    embedScript.src =
      'https://app.acessibilizar.com.br/embed-scripts/2c2ff6d8-59ba-4a9f-8c0c-d0ea5c71ee9a';
    embedScript.type = 'text/javascript';
    embedScript.async = true;
    embedScript.setAttribute(
      'embed-id',
      '2c2ff6d8-59ba-4a9f-8c0c-d0ea5c71ee9a',
    );

    document.head.appendChild(embedScript);

    // CORRIGINDO O ERRO AQUI
    const scriptCronitor = document.createElement('script');
    scriptCronitor.src = 'https://rum.cronitor.io/script.js';
    scriptCronitor.async = true;
    document.head.appendChild(scriptCronitor);

    scriptCronitor.onload = () => {
      window.cronitor =
        window.cronitor ||
        function (...args: any[]) {
          (window.cronitor.q = window.cronitor.q || []).push(args);
        };

      window.cronitor('config', {
        clientKey: '65f666269c62f0b0fb8ef819b7355d70',
      });
    };

    // CORRIGINDO A REDECLARAÇÃO DO SCRIPT
    const adoptScript = document.createElement('script');
    adoptScript.src =
      '//tag.goadopt.io/injector.js?website_code=f595d538-f2e4-4058-b251-9c904a94a14a';
    adoptScript.async = true;
    adoptScript.className = 'adopt-injector';

    const meta = document.createElement('meta');
    meta.name = 'adopt-website-id';
    meta.content = 'f595d538-f2e4-4058-b251-9c904a94a14a';

    document.head.appendChild(meta);
    document.head.appendChild(adoptScript);

    // Cleanup function para remover scripts ao desmontar o componente
    return () => {
      document.head.removeChild(embedScript);
      document.head.removeChild(adoptScript);
      document.head.removeChild(meta);
      document.head.removeChild(scriptCronitor);
    };
  }, []);

  return (
    <>
      <header className="z-40 fixed top-0 w-full h-[82px] flex items-center bg-[#F9F9F9] xl:rounded-b-3xl xl:shadow-card border-b border-b-neutral-lightest">
        <div className="xl:max-w-[1621px] 3xl:px-0 px-6  w-full mx-auto flex justify-between items-center h-[50px]">
          <Link to="/">
            <img
              src="/assets/logos/odonto-new.png"
              alt="Odontogroup"
              style={{ height: '40px' }}
            />
          </Link>
          {xl ? (
            <>
              <Navbar />
              <Link to="/quero-contratar?goTo=Comprar">
                <Button text="Contratar plano" Icon={CartIcon} />
              </Link>
            </>
          ) : (
            <button onClick={toggleMenu}>
              <MenuIcon />
            </button>
          )}
        </div>
      </header>
      <MobileMenu />
    </>
  );
}
