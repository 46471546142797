import { type ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

import { Button } from '../../../components/Button';
import { ArrowRightIcon } from '../../../components/icons/ArrowRightIcon';
import { Input } from '../../../components/Input';
import styles from './compPlans.module.css';
//import { error } from 'console';

const companyPlanFormSchema = z.object({
  name: z.string().min(1, { message: 'Campo obrigatório' }),
  cnpj: z.string().min(1, { message: 'Campo obrigatório' }),
  phone: z.string().min(1, { message: 'Campo obrigatório' }),
  email: z.string().min(1, { message: 'Campo obrigatório' }),
  currentPlan: z.optional(z.string()),
  planValue: z.optional(z.string()),
});
interface Dados {
  email: string;
  nome: string;
  fone: string;
  valor: number;
  plano: string;
  prod_comercial: string;
  preco: number;
}

type CompanyPlanFormSchema = z.infer<typeof companyPlanFormSchema>;

const url = `${process.env.REACT_APP_OG_POST_CRM_RD_STATION}`;
export default function CompanyPlanForm(): ReactElement {
  const { register, handleSubmit, formState, watch } =
    useForm<CompanyPlanFormSchema>({
      defaultValues: {},
      resolver: zodResolver(companyPlanFormSchema),
    });

  const { errors } = formState;
  const [resp, setResp] = useState<{
    done: boolean;
    error: boolean;
    msg: string;
  }>({} as any);
  const [load, setLoad] = useState(false);

  const [hasPlan, setHasPlan] = useState(false);

  function submitForm(event) {
    event.preventDefault();
    const cnpj = watch('cnpj');
    const email = watch('email');
    const name = watch('name');
    const plan = watch('currentPlan');
    const valor = watch('planValue');
    const phone = watch('phone');
    const dados: Dados = {
      email: email,
      nome: name,
      plano: plan,
      fone: phone,
      preco: 30,
      prod_comercial: 'odontoClinico',
      valor: Number(valor),
    };
    handleSubmit(async data => {
      try {
        setLoad(true);
        const username = `${process.env.REACT_APP_OG_USER}`;
        const senha = `${process.env.REACT_APP_OG_PASS}`;
        const url_login = `${process.env.REACT_APP_OG_URL}/login?user=${username}&password=${senha}`;
        const getToken = await fetch(url_login, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });
        const token = await getToken.json();
        const resp = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token.token,
          },
          body: JSON.stringify(dados),
        });
        const body = await resp.json();
        //console.log('retorno: ', resp);
        if (resp.status == 200) {
          setResp({
            done: true,
            error: false,
            msg: body.message ?? 'Indicação Enviada Com Sucesso!!!',
          });
        } else {
          throw new Error(body);
        }
      } catch (err) {
        //
        console.log(err);
        setResp({
          done: true,
          error: true,
          msg: 'Erro ao enviar mensagem verifique os campos e tente novamente.',
        });
      } finally {
        setLoad(false);
      }
    })();
  }

  return (
    <div className={styles.formContainer}>
      <img
        src="/assets/images/sol/half-sol-card.png"
        className="max-[1499px]:hidden -bottom-[141px] -right-32 relative h-[813px] w-[815px]"
      />
      <div className={styles.formBgContainer}>
        <h3 className={styles.formTitle}>
          Entre em contato para conhecer planos para mais de 200 vidas
        </h3>
        <form id="company-plan-form" onSubmit={submitForm}>
          <div className="flex flex-col gap-8 mt-8">
            <div className="grid grid-cols-2 gap-8 max-[748px]:grid-cols-1">
              <Input
                label="Razão Social"
                placeholder="Razão Social"
                errorMessage={errors.name?.message}
                {...register('name')}
              />
              <Input
                label="CNPJ"
                placeholder="00.000.000/0000-00"
                mask="99.999.999/9999-99"
                activateMask
                errorMessage={errors.cnpj?.message}
                {...register('cnpj')}
              />
            </div>

            <div className="grid grid-cols-2 gap-8 max-[748px]:grid-cols-1">
              <Input
                label="E-mail"
                placeholder="sample@mail.com"
                errorMessage={errors.email?.message}
                {...register('email')}
              />
              <Input
                label="Telefone"
                placeholder="(00) 00000-0000"
                activateMask
                mask={'(99) 99999-9999'}
                errorMessage={errors.phone?.message}
                {...register('phone')}
              />
            </div>

            <div className="bg-neutral-surface rounded-lg p-3">
              <p className="text-high-emphasis">
                A empresa já possui plano odontologico?
              </p>
              <div className="flex gap-10 mt-5">
                <button
                  onClick={() => setHasPlan(true)}
                  className="flex gap-3 justify-center items-center"
                  type="button"
                >
                  <div className="h-6 w-6 rounded-full border-2 border-primary-dark flex justify-center items-center">
                    {hasPlan ? (
                      <div className="h-3 w-3 bg-primary-dark rounded-full" />
                    ) : null}
                  </div>
                  <p className="text-sm text-medium-emphasis">Sim</p>
                </button>

                <button
                  onClick={() => setHasPlan(false)}
                  className="flex gap-3 justify-center items-center"
                  type="button"
                >
                  <div className="h-6 w-6 rounded-full border-2 border-primary-dark flex justify-center items-center">
                    {!hasPlan ? (
                      <div className="h-3 w-3 bg-primary-dark rounded-full" />
                    ) : null}
                  </div>
                  <p className="text-sm text-medium-emphasis">Não</p>
                </button>
              </div>
            </div>

            {hasPlan && (
              <div className="grid grid-cols-2 gap-8 max-[748px]:grid-cols-1">
                <Input
                  label="Qual o plano atual?"
                  placeholder="Plano"
                  errorMessage={errors.currentPlan?.message}
                  {...register('currentPlan')}
                />
                <Input
                  label="Qual o valor do plano atual?"
                  placeholder="Valor do plano"
                  errorMessage={errors.planValue?.message}
                  {...register('planValue')}
                />
              </div>
            )}
          </div>
          {resp.done && (
            <p
              className={`mt-1 mb-2 text-base ${
                !resp.error ? 'text-success-main' : 'text-destructive-main'
              }`}
            >
              {resp.msg}
            </p>
          )}
          <Button
            disabled={load}
            className="min-[748px]:ml-auto max-[748px]:w-full mt-6"
            text="Enviar"
            intent="primary"
            Icon={ArrowRightIcon}
            type="submit"
          />
        </form>
      </div>
    </div>
  );
}
