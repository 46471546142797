import { ReactElement, useEffect, useState } from 'react';

import { useRouteLoaderData } from 'react-router-dom';

import { InfoIcon } from '../../../../../icons/InfoIcon';
import { Input } from '../../../../../Input';
import { useHirePlanForm } from '../../../../../../contexts/HirePlanFormProvider';
import { Select } from '../../../../../Select';
import { format } from 'date-fns';
import { Button } from '../../../../../Button';

export function PaymentBilletForm(): ReactElement {
  const data: any = useRouteLoaderData('token');
  const { register, errors } = useHirePlanForm();

  return (
    <div className="max-[1000px]:flex-col max-[1000px]:items-center min-[748px]:mt-6 min-[748px]:p-6 min-[748px]:pt-4 w-full flex flex-col justify-center">
      <span className="text-primary-main text-xl font-medium mb-8">
        Data de vencimento
      </span>
      <div className="grid min-[748px]:grid-cols-2">
        <div className="grid grid-cols-1 gap-6">
          <div className="grid grid-cols-1 gap-8">
            <Select
              label="Selecione a data de vencimento"
              options={data.vencimentoBoletos.map(boleto =>
                format(new Date(boleto.select), 'dd/MM/yyyy'),
              )}
              placeholder="Data de vencimento"
              errorMessage={errors.payment?.billetDate?.message}
              {...register('payment.billetDate')}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center border border-info-main bg-info-container rounded-md w-full py-3 px-2 mt-6">
        <InfoIcon className="flex-none items-center min-w-[30px]" />
        <span className="flex-1 text-info-dark text-sm ml-2">
          Um link boleto de pagamento será enviado para seu email após o envio
          desse formulário.
        </span>
      </div>
    </div>
  );
}
