import type { ReactElement } from 'react';

import { useDentistsContext } from '../..';
import DocList from './DocList';
import Map from './Map';

export default function DentistsFound(): ReactElement {
  const { docList } = useDentistsContext();
  return (
    <div className="flex gap-4 max-[999px]:flex-col grow">
      <DocList docList={docList} />
      <Map docList={docList} />
    </div>
  );
}
