import type { ReactElement } from 'react';

import type { Dentist } from '../..';
import { ChevronRight } from '../../../icons/ChevronRight';
import { MapIcon } from '../../../icons/MapIcon';
import { PhoneIcon } from '../../../icons/PhoneIcon';
import styles from './docs.module.css';

const DocList = ({ docList }: { docList: Dentist[] }): ReactElement => (
  <ul className={styles.docList}>
    {docList.map(doc => (
      <li className={styles.item} key={doc.idFuncionario}>
        <div className={styles.docData}>
          <div>
            <p className={styles.docInfo}>
              {doc.tipoEstabelecimento ? doc.tipoEstabelecimento : 'Clínica'}
            </p>
            <h3 className={styles.docTitle}>{doc.nomeFantasia}</h3>
            <h3 className={styles.docTitle}>{doc.nomeFuncionario}</h3>
            <p className={styles.docInfo}>{doc.especialidade}</p>
          </div>
          <ChevronRight className="max-[999px]:hidden min-w-[36px] min-h-[36px]" />
        </div>

        <div className="p-5">
          <div className="flex items-center gap-4">
            <div className="text-lg text-medium-emphasis">
              <p> {doc.qualiss} </p>
              <p> {doc.produtos} </p>
            </div>
          </div>
        </div>
        <div className="p-5">
          <div className="flex items-center gap-4">
            <MapIcon />
            <div className="text-lg text-medium-emphasis">
              <p>
                {doc.logradouro} {doc.bairro} - {doc.ufSigla} {doc.cidade}
              </p>
            </div>
          </div>
          <div className="flex items-center gap-4 mt-3">
            <PhoneIcon />
            <p className="text-lg text-medium-emphasis">
              {doc.telefone?.[0]?.telefone}
            </p>
          </div>
        </div>
      </li>
    ))}
  </ul>
);

export default DocList;
