import { ReactElement, ReactNode } from 'react';

import { RefreshIcon } from '../icons/RefreshIcon';

interface Props {
  children: ReactNode;
  showSearch?: boolean;
  reset: () => void;
}

const DentistSearchContainer = ({
  children,
  showSearch = false,
  reset,
}: Props): ReactElement => (
  <section className="mt-5 bg-neutral-white rounded-xl shadow-card mx-5 p-5">
    <div className="flex justify-between items-center">
      <div className="flex gap-2 items-center">
        <img
          className="max-[1300px]:max-w-[1.5rem]"
          src="/assets/images/icons/search.svg"
        />
        <h2 className="text-4xl max-[1300px]:text-lg max-[1300px]:font-semibold text-primary-main">
          Busca de dentista
        </h2>
      </div>
      {showSearch && (
        <button
          className="max-[1300px]:hidden flex items-center gap-1"
          onClick={reset}
        >
          <RefreshIcon />
          <p className="text-primary-main text-sm font-bold uppercase">
            Fazer uma nova busca
          </p>
        </button>
      )}
    </div>
    {children}
  </section>
);

export default DentistSearchContainer;
