import { ReactElement } from 'react';
import { format, parseISO } from 'date-fns';
import { LabelledInformationProposal } from '../LabelledInformationProposal';

type Props = {
  data: {
    dataCadastro: string;
    dataVencimento: string;
    dataVigencia?: string;
    nomeVendedor: string;
    nome: string;
    email: string;
    ddd: string;
    telefone: string;
  };
};

export function InformationSectionProposal(state: Props): ReactElement {
  const telefone = state.data.telefone
    ? state.data.telefone.replace(/\D/g, '')
    : '';
  return (
    <section className="mb-14">
      <ul className="flex flex-wrap gap-10">
        <LabelledInformationProposal
          label="Data de cadastro"
          value={
            state.data.dataCadastro
              ? format(parseISO(state.data.dataCadastro), 'dd/MM/yyyy')
              : 'Data inválida'
          }
          largeText
        />
        {state.data.dataVigencia && (
          <LabelledInformationProposal
            label="Data de inicio de vigência"
            value={
              state.data.dataVigencia
                ? format(parseISO(state.data.dataVigencia), 'dd/MM/yyyy')
                : 'Data inválida'
            }
            largeText
          />
        )}
        <LabelledInformationProposal
          label="Data de vencimento do pagamento"
          value={
            state.data.dataVencimento
              ? format(parseISO(state.data.dataVencimento), 'dd/MM/yyyy')
              : 'Data inválida'
          }
          largeText
        />
        <LabelledInformationProposal
          label="Vendedor"
          value={state.data.nomeVendedor}
          largeText
        />
        <LabelledInformationProposal
          label="Beneficiário titular & responsável"
          value={state.data.nome}
          largeText
        />
        <LabelledInformationProposal
          label="E-mail"
          value={state.data.email}
          largeText
        />
        <LabelledInformationProposal
          label="Número  de celular"
          value={`${state.data.telefone ? telefone.substring(0, 2) : ''} ${
            state.data.telefone ? telefone.substring(2, 11) : ''
          }`}
          largeText
        />
      </ul>
    </section>
  );
}
