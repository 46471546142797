import { ReactElement } from 'react';

import { Button } from '../../Button';

const DentistsNotFound = (): ReactElement => (
  <div className="w-full flex flex-col gap-5 items-center text-center">
    <h3 className="text-[2.5rem] text-high-emphasis max-[1000px]:text-2xl">
      Nenhum resultado para a sua pesquisa!
    </h3>
    <img src="/assets/images/notFound.svg" />
    <p className="text-medium-emphasis text-2xl max-[1000px]:text-[1.125rem]">
      Gostaria de indicar seu dentista?
    </p>
    <Button text="Sim" className="w-[300px] max-w-[100%]" />
  </div>
);

export default DentistsNotFound;
