import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Paper,
  Modal,
} from '@mui/material';

const CarteirinhaDigital = ({
  dependentes,
  handleFormSubmit,
  handleCpfChange,
  cpf,
}: {
  dependentes: any[];
  handleFormSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  handleCpfChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  cpf: string;
}) => {
  const [open, setOpen] = useState(false);
  const [selectedDependente, setSelectedDependente] = useState<any | null>(
    null,
  );
  const [pdfBase64, setPdfBase64] = useState('');
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const toggleExpandRow = (index: number) => {
    setExpandedRow(prevIndex => (prevIndex === index ? null : index));
  };

  const handleOpen = async (dependente: any) => {
    setSelectedDependente(dependente);
    setOpen(true);
    await handleCarteirinhaClick(dependente.Id);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedDependente(null);
    setPdfBase64('');
  };

  const handleCarteirinhaClick = async (id: string) => {
    try {
      const urlBase = process.env.REACT_APP_URL_BASE;
      const response = await axios.post(
        `${urlBase}/SYS/services/Vendedor.aspx/CarteiraVirtual`,
        {
          dados: { codigo: id },
          token: 'YUISKDAMNVJAFTYU134JRB780S67AUHJ3NI2978ADF19814TH1',
        },
      );

      const pdfData = response.data.dados;
      if (pdfData.trim().length !== 0) {
        setPdfBase64(pdfData);
      }
    } catch (error) {
      console.error('Erro ao buscar carteirinha:', error);
    }
  };
  console.log('dependentes: ', dependentes);

  return (
    <>
      <Typography variant="body1" gutterBottom>
        Informe o CPF do titular ou Responsável financeiro.
      </Typography>
      <form id="form" onSubmit={handleFormSubmit}>
        <TextField
          fullWidth
          margin="normal"
          label="Número do CPF"
          variant="outlined"
          value={cpf}
          onChange={handleCpfChange}
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Enviar
        </Button>
      </form>
      {dependentes.length > 0 && (
        <Box mt={4}>
          <table width="100%">
            <thead style={{ background: '#272D65', color: '#FFFFFF' }}>
              <tr>
                <th style={{ textAlign: 'center' }}>ID Contrato</th>
                <th style={{ textAlign: 'center' }}>Contratos</th>
              </tr>
            </thead>
            <tbody>
              {dependentes.map((contrato, index) => (
                <React.Fragment key={index}>
                  <tr
                    onClick={() => toggleExpandRow(index)}
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    <td style={{ textAlign: 'center' }}>
                      {contrato.Dependente[0].IdEmpresa}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      {contrato.Dependente[0].NomeEmpresa}
                    </td>
                  </tr>
                  {expandedRow === index && contrato.Dependente && (
                    <tr>
                      <td colSpan={6}>
                        <table width="96%" style={{ margin: '2%' }}>
                          <thead
                            style={{
                              background: '#DDE1FF',
                              color: '#272D65',
                            }}
                          >
                            <tr>
                              <th style={{ textAlign: 'center' }}>id</th>
                              <th style={{ textAlign: 'center' }}>Nome</th>
                              <th style={{ textAlign: 'center' }}></th>
                            </tr>
                          </thead>
                          <tbody>
                            {contrato.Dependente.map((dependente, index) => (
                              <tr key={index}>
                                <td style={{ textAlign: 'center' }}>
                                  {dependente.Id}
                                </td>
                                <td>{dependente.Nome}</td>
                                <td>
                                  <Button
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={() => handleOpen(dependente)}
                                  >
                                    Ver Carteirinha
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </Box>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          style={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            position: 'absolute',
            backgroundColor: 'white',
            padding: '20px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            borderRadius: '10px',
            width: '80%',
            height: '80%',
          }}
        >
          {pdfBase64 ? (
            <iframe
              src={`data:application/pdf;base64,${pdfBase64}`}
              title="Carteirinha"
              width="100%"
              height="100%"
            />
          ) : (
            <Typography variant="h6" id="simple-modal-title">
              Carregando carteirinha...
            </Typography>
          )}
        </div>
      </Modal>
    </>
  );
};

const Carteirinha: React.FC = () => {
  const [cpf, setCpf] = useState('');
  const [dependentes, setDependentes] = useState<any[]>([]);
  const [pdfBase64, setPdfBase64] = useState('');
  const [recaptchaValue, setRecaptchaValue] = useState<string | null>(null);
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const token = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  const handleCpfChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setCpf(event.target.value);
  };
  useEffect(() => {
    console.log('token', token);
  }, []);

  const handleFormSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    event.preventDefault();

    if (!recaptchaValue) {
      alert('Por favor, complete o reCAPTCHA.');
      return;
    }

    setDependentes([]);
    setPdfBase64('');
    const urlBase = process.env.REACT_APP_URL_BASE;

    const cleanedCpf = cpf.replace(/[^\d]+/g, '');
    try {
      const response = await axios.post(
        `${urlBase}/SYS/services/Vendedor.aspx/BuscaAssociado`,
        {
          dados: { cpf: cleanedCpf, incluirDependentes: 1 },
          token: 'YUISKDAMNVJAFTYU134JRB780S67AUHJ3NI2978ADF19814TH1',
          pageRequest: { Page: 1, Size: 15 },
        },
      );
      const dados = response.data.dados.Data;

      const dadosFiltrados = dados.filter(
        dado =>
          dado.Dependente && dado.Dependente.some(dep => dep.Situacao === 1),
      );
      console.log('dependentesAtivos: ', dadosFiltrados);
      if (dadosFiltrados.length === 0) {
        alert('Nenhum Associado ativo encontrado.');
        return;
      }
      setDependentes(dadosFiltrados);
    } catch (error) {
      console.error('Erro ao buscar associado:', error);
    } finally {
      recaptchaRef.current?.reset();
    }
  };

  const handleRecaptchaChange = (value: string | null): void => {
    setRecaptchaValue(value);
  };

  return (
    <Container>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Paper
          elevation={3}
          style={{ padding: '2rem', maxWidth: '600px', width: '100%' }}
        >
          <Typography variant="h5" component="h3" gutterBottom>
            Tenha acesso à sua carteirinha digital!
          </Typography>
          <CarteirinhaDigital
            dependentes={dependentes}
            handleFormSubmit={handleFormSubmit}
            handleCpfChange={handleCpfChange}
            cpf={cpf}
          />
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={token}
            onChange={handleRecaptchaChange}
          />
          {pdfBase64 && (
            <Box mt={4}>
              <embed
                src={`data:application/pdf;base64,${pdfBase64}`}
                type="application/pdf"
                width="100%"
                height="600px"
                style={{ marginTop: '35px', border: '1px solid #ccc' }}
              />
              <Box mt={2} textAlign="center">
                <Button
                  variant="contained"
                  color="secondary"
                  href={`data:application/pdf;base64,${pdfBase64}`}
                  download="carteirinha.pdf"
                >
                  Download...
                </Button>
              </Box>
            </Box>
          )}
        </Paper>
      </Box>
    </Container>
  );
};

export default Carteirinha;
