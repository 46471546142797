import type { ReactElement } from 'react';

import ChartSection from './Chart';
import FinancialSection from './Financial';
import SurveySection from './Survey';

const Transparency = (): ReactElement => (
  <>
    <section className="mt-20">
      <img
        src="/assets/images/banners/transparency.jpeg"
        alt="Transparencia"
        className="max-h-[970px] w-full object-cover max-[1000px]:h-[525px]"
      />
    </section>
    <ChartSection />
    <SurveySection />
    <FinancialSection />
  </>
);

export default Transparency;
