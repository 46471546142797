import * as z from 'zod';

import { schema } from '.';
import { validaSchema } from './validaSchema';

export const creditCardSchema = z.object({
  payment: z.optional(
    z.object({
      number: z.optional(z.string().min(1, { message: 'Campo obrigatório' })),
      name: z.optional(z.string().min(1, { message: 'Campo obrigatório' })),
      /* cpf: z.optional(
        z
          .string()
          .min(14, { message: 'Requer 14 caracteres' })
          .regex(/^(?!\d{11})\d{3}\.?\d{3}\.?\d{3}-?\d{2}$/, {
            message: 'CPF inválido',
          }),
      ), */
      expireDate: z.optional(
        z
          .string()
          .min(1, { message: 'Campo obrigatório' })
          .regex(/^\d{2}\/\d{2}$/, { message: 'Data inválida' }),
      ),
      cvv: z.optional(z.string().min(1, { message: 'Campo obrigatório' })),
      instalment: z.optional(
        z.string().min(1, { message: 'Campo obrigatório' }),
      ),
    }),
  ),

  user: schema,
  valida: validaSchema,
});

export type CreditCardSchema = z.infer<typeof creditCardSchema>;
