import { create } from 'zustand';

type VidasState = {
  count: any;
  setVidaCount: (count: number) => void;
};

export const useVidaCountStore = create<VidasState>((set, get) => ({
  count: 0,
  setVidaCount: data => set(() => ({ count: data })),
  action: () => {
    const count = get().count;
  },
}));
