import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { Button } from '../components/Button';
import { PageHelmet } from '../components/PageHelmet';

export function CredentialNetPage(): ReactElement {
  return (
    <>
      <h2 className="mt-32 mb-5 text-[2rem] font-semibold text-center text-primary-main">
        Rede credenciada
      </h2>
      <div id="iframe" className="h-[700px]">
        <iframe
          className="w-full h-full border-none m-0 p-0 overflow-hidden"
          src="https://odontogroup.s4e.com.br/SYS/rede_atendimento/rede_atendimento.aspx?modal=1&rede_externa=1"
        ></iframe>
      </div>
    </>
  );
}
