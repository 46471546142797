import { create } from 'zustand';

type dependentAddState = {
  data: any;
  setdependentAddData: (data: boolean) => void;
};

export const useDependentAddDataStore = create<dependentAddState>(
  (set, get) => ({
    data: {},
    setdependentAddData: data => set(() => ({ data: data })),
    action: () => {
      const data = get().data;
    },
  }),
);

type dependentOpenModalState = {
  data: any;
  setdependentOpenModalData: (data: boolean) => void;
};

export const useDependentOpenModalDataStore = create<dependentOpenModalState>(
  (set, get) => ({
    data: {},
    setdependentOpenModalData: data => set(() => ({ data: data })),
    action: () => {
      const data = get().data;
    },
  }),
);

type dependentOpenFormState = {
  data: any;
  setdependentOpenFormData: (data: boolean) => void;
};

export const useDependentOpenFormDataStore = create<dependentOpenFormState>(
  (set, get) => ({
    data: {},
    setdependentOpenFormData: data => set(() => ({ data: data })),
    action: () => {
      const data = get().data;
    },
  }),
);

type DependetValueState = {
  vl_total: number;
  setValorTotal: (vl_total: number) => void;
};

export const useDependentValueStore = create<DependetValueState>(
  (set, get) => ({
    vl_total: 0,
    setValorTotal: data => set(() => ({ vl_total: data })),
    action: () => {
      const vl_total = get().vl_total;
    },
  }),
);
type DependetIdState = {
  id: number;
  setId: (id: number) => void;
};

export const useDependentIdStore = create<DependetIdState>((set, get) => ({
  id: 0,
  setId: data => set(() => ({ id: data })),
  action: () => {
    const id = get().id;
  },
}));
