import * as z from 'zod';

import { funcionarioSchema, fileSchema } from './functionarysSchema';

export const schema = z.object({
  razSoc: z.optional(z.string().min(1, { message: 'Campo obrigatório' })),
  fullName: z.optional(z.string().min(1, { message: 'Campo obrigatório' })),
  stateInscription: z.optional(
    z.string().min(1, { message: 'Campo obrigatório' }),
  ),
  functionaryQuantity: z.optional(
    z.string().min(1, { message: 'Campo obrigatório' }),
  ),
  phone: z.optional(
    z
      .string()
      .min(15, { message: 'Campo obrigatório' })
      .regex(/^\(\d{2}\)\s\d{5}-\d{4}$/, { message: 'Telefone inválido' }),
  ),
  email: z.optional(
    z
      .string()
      .min(1, { message: 'Campo obrigatório' })
      .email({ message: 'E-mail inválido' }),
  ),
  token: z.optional(
    z
      .string()
      .min(6, { message: 'Campo obrigatório' })
      .regex(/^\d{1}\s.\s\d{1}\s.\s\d{1}\s.\s\d{1}\s.\s\d{1}\s.\s\d{1}$/, {
        message: 'Token inválido',
      }),
  ),
  documents: z.optional(
    z.object({
      cnpj: z.optional(
        z
          .string()
          .min(18, { message: 'Campo obrigatório' })
          .regex(/^(?!\d{18})\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2}$/, {
            message: 'CNPJ inválido',
          }),
      ),
      cpf: z.optional(
        z
          .string()
          .min(14, { message: 'Campo obrigatório' })
          .regex(/^(?!\d{11})\d{3}\.?\d{3}\.?\d{3}-?\d{2}$/, {
            message: 'CPF inválido',
          }),
      ),
    }),
  ),
  address: z.optional(
    z.object({
      cep: z.optional(
        z
          .string()
          .min(9, { message: 'Campo obrigatório' })
          .regex(/^[0-9]{5}-[0-9]{3}$/, { message: 'CEP inválido' }),
      ),
      neighborhood: z.optional(
        z.string().min(1, { message: 'Campo obrigatório' }),
      ),
      uf: z.optional(z.string().min(1, { message: 'Campo obrigatório' })),
      city: z.optional(z.string().min(1, { message: 'Campo obrigatório' })),
      street: z.optional(z.string().min(1, { message: 'Campo obrigatório' })),
      number: z.optional(z.string().min(1, { message: 'Campo obrigatório' })),
      complement: z.optional(z.string()),
    }),
  ),
  birthDate: z.optional(
    z
      .string()
      .min(1, { message: 'Campo obrigatório' })
      .regex(/^(\d{2})\/(\d{2})\/(\d{4})$/, { message: 'Data inválida' }),
  ),
  CNPJCard: fileSchema.optional(),
  CSMei: fileSchema.optional(),
  ComprovanteResi: fileSchema.optional(),
  Identidade: fileSchema.optional(),

  funcionarios: z.optional(z.array(funcionarioSchema)),
  paymentMethod: z.optional(z.string()),
});

export type Schema = z.infer<typeof schema>;
