import type { ReactElement } from 'react';

import { NewPersonalPlans } from '../../components/NewPersonalPlans';

const PlanSection = (): ReactElement => (
  <section className="mt-32 mb-44 max-[1000px]:mt-80">
    <div
      id="Comprar"
      className="text-cente flex flex-col justify-center items-center"
    >
      <h3 className="font-semibold text-[2.625rem] max-[999px]:text-lg max-[999px]:text-center text-primary-main">
        Planos para você e sua família
      </h3>
      <p className="text-2xl text-new-orange max-[999px]:text-base text-center max-w-[1089px]">
        Conheça nossos planos e escolha a opção que se encaixa com seu perfil.
        Temos um plano exclusivo para você.
      </p>
    </div>
    <NewPersonalPlans className="min-[1200px]:-mb-32" />
  </section>
);

export default PlanSection;
