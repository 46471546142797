import { ReactElement } from 'react';

import BigBanner from '../../components/BigBanner';
import { PageHelmet } from '../../components/PageHelmet';
import OuvidoriaForm from './OuvidoriaForm';

export default function OuvidoriaPage(): ReactElement {
  return (
    <div className="h-full min-h-[calc(100vh-549px)]">
      <PageHelmet title="Ouvidoria | OdontoGroup" />

      <BigBanner
        href="#ouvidoria-form"
        // title="Ouvidoria"
        page="ouvidoria"
        hideBtn
        mainText="Fique à vontade para elogiar, reclamar e sugerir melhorias. Nosso time retornará em breve!"
      />

      <section className="min-h-[300px] max-[748px]:block max-[748px]:z-10">
        <div className="min-[1000px]:flex items-center justify-center gap-20 -mb-32 pl-20 pr-20 max-[1400px]:p-5">
          <img
            className="max-[1300px]:hidden rounded-bl-[10rem] max-w-[615px] max-h-[815px]"
            src="/assets/images/ouvidoria.png"
            alt="Sorriso"
          />
          <div className="bg-white max-h-[40%] rounded p-10 max-[748px]:p-5 min-[748px]:min-w-[676px] shadow-card">
            <h3 className="text-primary-main font-semibold text-[2rem] max-[1000px]:text-[1.125rem] max-w-[500px]">
              Registrar uma mensagem na ouvidoria
            </h3>
            <OuvidoriaForm />
          </div>
        </div>
      </section>

      <section className="min-[1301px]:bg-new-blue min-h-[200px] justify-between max-[748px]:z-1 " />
    </div>
  );
}
