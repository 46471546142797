import { ReactElement, useEffect, useState } from 'react';

import { useUpdateDependentForm } from '../../../../../contexts/UpdateDependentFormProviderGdf';
import { GoForwardButton } from '../../../GoForwardButton';
import { useWindowSizeStore } from '../../../../../stores/useWindowSizeStore';
import { CartIcon } from '../../../../icons/CartIcon';
import Loading from '../../../../icons/Loading';
import { ErrorModal } from '../../../../Modal/Modal';
import { useLoadingStore } from '../../../../../stores/useLoadingStore';
import { useError } from '../../../../../stores/useError';

export function AceiteForm(): ReactElement {
  const { handleSubmit, onSubmit } = useUpdateDependentForm();

  const width = useWindowSizeStore(state => state.width);

  const loading = useLoadingStore(state => state.loading);
  const error = useError(state => state.error);
  const title = useError(state => state.title);
  const text = useError(state => state.text);

  return (
    <form
      action=""
      className="max-w-[766px] w-full"
      onSubmit={handleSubmit(onSubmit)}
    >
      {error && <ErrorModal open={true} title={title} text={text} />}
      <>
        <h2 className="text-[2rem] font-semibold text-left text-primary-main">
          Termo de aceite
        </h2>
        <p className="font-normal mt-1 text-justify mb-8">
          Confirmo minha adesão ao Auxílio Odontológico oferecido pelo Governo
          do Distrito Federal, conforme disposto no Diário Oficial de julho de
          2024 (Lei Nº 7.524 e Decreto Nº 46.102 de agosto de 2024).
        </p>
        <p className="font-normal mt-1 text-justify mb-8">
          O plano odontológico é 99% subsidiado para servidores titulares
          estaduais da administração direta, autárquica e fundacional do DF e
          está sendo disponibilizado através da Odontogroup Sistema de Saúde
          Ltda., operadora credenciada.
        </p>
        <p className="font-normal mt-1 text-justify mb-8">
          Estou ciente que o plano não possui carência e requer uma permanência
          mínima de 12 meses.E reforço que estou ciente de que os meus dados
          pessoais serão tratados conforme as disposições da Lei Geral de
          Proteção de Dados (LGPD).
        </p>
      </>
      <GoForwardButton
        disabled={loading}
        addBackButton
        Icon={loading ? Loading : CartIcon}
        desktopText={loading ? '' : 'Concordar com os Termos'}
        mobileText={loading ? '' : 'Concordar com os Termos'}
      />
    </form>
  );
}
