import type { FC, ReactElement, ComponentProps } from 'react';

import { cva, type VariantProps } from 'class-variance-authority';

const buttonStyles = cva(
  'flex justify-center items-center text-sm py-3 px-4 rounded-lg flex gap-2 focus:outline-none focus:ring-2 focus:ring-offset-2 flex items-center h-12 disabled:opacity-40',
  {
    variants: {
      intent: {
        primary:
          'text-neutral-with bg-primary-main hover:bg-primary-dark focus:ring-primary-light focus:ring-offset-white',
        secondary:
          'text-primary-main border-2 border-primary-main bg-transparent hover:bg-primary-container focus:ring-primary-light focus:ring-offset-white',
        tertiary:
          'group text-primary-main border-2 border-transparent bg-transparent hover:bg-primary-container hover:text-primary-main focus:ring-primary-main',
        quaternary:
          'group text-white border-2 border-white bg-transparent hover:bg-primary-container hover:text-primary-main focus:ring-white focus:ring-offset-white',
        borderless:
          'group text-white border-0 border-white bg-transparent hover:bg-primary-container hover:text-primary-main focus:ring-white focus:ring-offset-white',
        newOrange:
          'text-neutral-with bg-new-orange hover:opacity-80 focus:ring-primary-light focus:ring-offset-white',
        outlined:
          'group text-new-orange border-2 border-new-orange bg-transparent hover:bg-primary-container hover:text-primary-main focus:ring-white focus:ring-offset-white',
      },
    },
    defaultVariants: {
      intent: 'primary',
    },
  },
);

type ValidTags = keyof JSX.IntrinsicElements;

type Props<T extends ValidTags | React.JSXElementConstructor<any>> = {
  text: any;
  Icon?: FC<{ className?: string }>;
  disabled?: boolean;
  className?: string;
  iconClassName?: string;
  invertIcon?: boolean;
  iconProps?: any;
  tag?: T | ValidTags | React.JSXElementConstructor<any>;
} & (ComponentProps<T> & VariantProps<typeof buttonStyles>);

export function Button<T extends ValidTags = 'button'>({
  text,
  intent,
  Icon,
  disabled,
  className,
  iconClassName,
  invertIcon = false,
  iconProps = {},
  tag = 'button',
  ...props
}: Props<T>): ReactElement {
  const Tag: any = tag;
  return (
    <Tag
      disabled={disabled}
      className={`${buttonStyles({ intent })} ${className}`}
      {...props}
    >
      {Icon && !invertIcon && <Icon className={iconClassName} {...iconProps} />}
      <span className="inline-block uppercase font-bold tracking-normal">
        {text}
      </span>
      {Icon && invertIcon && <Icon className={iconClassName} {...iconProps} />}
    </Tag>
  );
}
