import type { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { Button } from '../../components/Button';
import styles from './home.module.css';

const YourPlanSection = (): ReactElement => (
  <section className={styles.yourPlanContainer}>
    <img src="/assets/images/home/girl.jpeg" className={styles.yourPlanImage} />
    <div className="max-[900px]:text-center">
      <h3 className="text-customBigger text-white mb-10 max-[1470px]:text-2xl max-[900px]:mt-10">
        Ainda não é Beneficiário Odontogroup?
      </h3>
      <h1 className="text-8xl font-semibold text-white uppercase max-[1470px]:text-5xl max-[900px]:max-w-[316px] max-[900px]:mx-auto">
        Faça seu <br /> plano odonto!
      </h1>
      <Button
        tag={Link}
        to="/quero-contratar"
        text="Quero Comprar"
        className="mt-10 max-[900px]:mx-auto w-fit"
        intent="newOrange"
      />
    </div>
  </section>
);

export default YourPlanSection;
