import { ReactElement } from 'react';

import { HirePlanFormProvider } from '../../../contexts/HirePlanFormProviderGdf';
//import { HirePlanFormProvider } from '../../../contexts/HirePlanFormProvider';
import { useHirePlanFormStepStore } from '../../../stores/useHirePlanFormStepStore';
import { useWindowSizeStore } from '../../../stores/useWindowSizeStore';
import { GDFBreadcrumbSteps } from '../data/breadcrumb';
import { HirePlanFormBreadcrumb } from '../HirePlanFormBreadcrumb';
import { HirePlanFormStepHandler } from '../ServidorGDF/HirePlanFormStepHandler';
import { HirePlanMobileBreadcrumb } from '../mobile/HirePlanMobileBreadcrumb';
import { useRouteLoaderData } from 'react-router-dom';

export function HirePlanForm(): ReactElement {
  const GDFPlanData: any = useRouteLoaderData('token-servidor');
  const data = GDFPlanData;

  const step = useHirePlanFormStepStore(state => state.step);
  const width = useWindowSizeStore(state => state.width);

  return (
    <div className="bg-white min-[748px]:pt-4 min-[748px]:px-14 pb-14 flex flex-col min-[748px]:shadow-card min-[748px]:rounded-b-xl items-center">
      {width >= 748 ? (
        <HirePlanFormBreadcrumb.Root>
          {GDFBreadcrumbSteps.map((item, index) => (
            <HirePlanFormBreadcrumb.Item
              key={item.name}
              text={item.name}
              number={index + 1}
              active={item.step === step}
            />
          ))}
        </HirePlanFormBreadcrumb.Root>
      ) : (
        <HirePlanMobileBreadcrumb />
      )}

      <HirePlanFormProvider>
        <HirePlanFormStepHandler />
      </HirePlanFormProvider>
    </div>
  );
}
